<template>
  <div v-if="isProtoHeartHemodynamicInformationEnabled">
    <form>
      <sub-section
        sub-section-id="proto-hemodynamic-nformation-section"
        :title="title"
        >
      <template v-slot:contents>
      
        <!-- Form layout -->
      
          <form-layout
          form-id="HemodynamicInformation-form">
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group">
                  <date-input
                    inputId="test_date"
                    name='Test Date'
                    v-model="editState.test_date"   
                  />
                </div>
                <div class="standard-form-group">
                  <select-input
                    select-id="test-type"
                    name='Type'
                    :options="testTypeOptions"
                    v-model="editState.test_type">              
                  </select-input>
                </div>
                <div class="standard-form-group">
                  <checkbox-input 
                    name="continuous-monitoring" 
                    labelName="Continuous Monitoring"
                    inputId="continuous_monitoring" 
                    v-model="editState.use_continuous_monitoring" 
                    label='Yes' />
                </div>
                <div class="standard-form-group">
                  <date-input
                    inputId="continuous_monitoring_startdate"
                    name='Continuous Monitoring Start Date'
                    :disabled="!editState.use_continuous_monitoring"
                    v-model="editState.continuous_monitoring_startdate"   
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="systolic_pa_pressure"
                    name="Systolic PA Pressure"
                    step="0.1"
                    v-model="editState.systolic_pa_pressure"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="diastolic_pa_pressure"
                    name="Diastolic PA Pressure"
                    step="0.1"
                    v-model="editState.diastolic_pa_pressure"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="mean_pa_pressure"
                    name="Mean PA Pressure"
                    step="0.1"
                    v-model="editState.mean_pa_pressure"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="pcw_pressure"
                    name="Pulmonary Capillary Wedge (PCW) Pressure"
                    step="0.1"
                    v-model="editState.pcw_pressure"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="cardiac_index"
                    name="Cardiac Index"
                    step="0.1"
                    v-model="editState.cardiac_index"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="cardiac_output"
                    name="Cardiac Output"
                    step="0.1"
                    v-model="editState.cardiac_output"
                  />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="pvr_woods"
                    name="PVR Woods"
                    step="0.1"
                    v-model="editState.pvr_woods"
                  />
                </div>
                <div class="standard-form-group">
                  <select-input
                    select-id="pvr_reactivity"
                    name='PVR Reactivity'
                    :options="pvrReactivityOptions"
                    v-model="editState.pvr_reactivity">              
                  </select-input>
                </div>
                <div class="standard-form-group">
                  <checkbox-input 
                    name="thermal-dilution" 
                    labelName="FICK or Thermal Dilution"
                    inputId="thermal_dilution" 
                    v-model="editState.thermal_dilution" 
                    label='Yes' />
                </div>
                <div class="standard-form-group">
                  <number-input
                    inputId="transpulmonary_gradient"
                    name="Transpulmonary Gradient"
                    step="0.1"
                    v-model="editState.transpulmonary_gradient"
                  />
                </div>
              </div>
            </template>
          </form-layout>   
          <form-layout
          form-id="cardiac-catheter-form">
            <template v-slot:title>
              <!-- Mode indicator / subsection form title -->
              <legend>
                <h5 v-if="isNew" class="legend-title">
                  Cardiac Catheter
                </h5>
              </legend>
            </template>
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group">
                  <checkbox-input 
                    name="cardiac-catheter" 
                    labelName="Cardiac Catheter"
                    inputId="cardiac_catheter" 
                    v-model="editState.cardiac_catheter" 
                    label='Yes' />
                </div>
                <div class="standard-form-group">
                  <select-input
                    select-id="disease"
                    name='Disease'
                    :options="diseaseOptions"
                    v-model="editState.cardiac_catheter_disease">              
                  </select-input>
                </div>
                <div class="standard-form-group">
                  <date-input
                    inputId="cardiac_catheter_test_date"
                    name='Test Date'
                    v-model="editState.cardiac_catheter_test_date"   
                  />
                </div>
              </div>
            </template>
            <template v-slot:save>
              <save-toolbar
                :show="true"     
                ref="saveHemodynamicInformation"
                label="Save Hemodynamic Information"
                :cancelButton="true"
                @save="performSave()"
                @cancel="clearForm()"
              />
            </template>
  
          </form-layout>   
        </template>
      </sub-section>

    </form>
  </div>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface HemodynamicInformationForm {
  test_date: Date|null,
  test_type: string | null,
  use_continuous_monitoring: boolean,
  continuous_monitoring_startdate: Date|null,
  systolic_pa_pressure: number | null,
  diastolic_pa_pressure: number | null,
  mean_pa_pressure: number | null,
  pcw_pressure: number | null,
  cardiac_index: number | null,
  cardiac_output: number | null,
  pvr_woods: number | null,
  pvr_reactivity: string | null,
  thermal_dilution: boolean
  transpulmonary_gradient: number | null;
  cardiac_catheter: boolean;
  cardiac_catheter_disease: string | null,
  cardiac_catheter_test_date:  Date|null
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    TimeInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoSodiumMelds extends mixins(DateUtilsMixin) {

  private title: any = "Hemodynamic Information";
  private isNew: any = true;

  private editState: HemodynamicInformationForm = {
    test_date: null,
    test_type: '',
    use_continuous_monitoring: false,
    continuous_monitoring_startdate: null,
    systolic_pa_pressure: null,
    diastolic_pa_pressure: null,
    mean_pa_pressure: null,
    pcw_pressure: null,
    cardiac_index: null,
    cardiac_output: null,
    pvr_woods: null,
    pvr_reactivity: '',
    thermal_dilution: false,
    transpulmonary_gradient: null,
    cardiac_catheter: false,
    cardiac_catheter_disease: '',
    cardiac_catheter_test_date: null

  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  public perPage = 10;
  public currentPageTable = 1;

  get isProtoHeartHemodynamicInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.HeartHemodynamicInformation);
  }

  async mounted() {
    // No-op if prototypes not enabled
    if (!useCurrentPageStore().configuration.prototypes) return;

    // Make a per-recipient prototype bucket
    this.protoBucket = new UIPrototypeBucket(PROTOTYPES.HeartHemodynamicInformation, { recipientId: this.$route.params.id });

    // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
    const response: HemodynamicInformationForm = await this.protoBucket.getItem(EDITSTATE_KEY);
    if (!response) return;

    this.editState = {
      test_date: response.test_date,
      test_type: response.test_type,
      use_continuous_monitoring: response.use_continuous_monitoring,
      continuous_monitoring_startdate: response.continuous_monitoring_startdate,
      systolic_pa_pressure: response.systolic_pa_pressure,
      diastolic_pa_pressure: response.diastolic_pa_pressure,
      mean_pa_pressure: response.mean_pa_pressure,
      pcw_pressure: response.pcw_pressure,
      cardiac_index: response.cardiac_index,
      cardiac_output: response.cardiac_output,
      pvr_woods: response.pvr_woods,
      pvr_reactivity: response.pvr_reactivity,
      thermal_dilution: response.thermal_dilution,
      transpulmonary_gradient: response.transpulmonary_gradient,
      cardiac_catheter: response.cardiac_catheter,
      cardiac_catheter_disease: response.cardiac_catheter_disease,
      cardiac_catheter_test_date: response.cardiac_catheter_test_date
    };
  }

  private testTypeOptions = [{
    code: 0,
    value: 'FloTrac'
    }, {
      code: 1,
      value: 'Vigileo'
    }
  ];

  private pvrReactivityOptions = [{
    code: 0,
    value: 'Reactive'
    }, {
      code: 1,
      value: 'Non-Reactive'
    },
    {
      code: 2,
      value: 'Test Not Done'
    },
    {
      code: 3,
      value: 'Unknown'
    }
  ];

  private diseaseOptions = [{
    code: 0,
    value: 'Mild'
    }, {
      code: 1,
      value: 'Moderate'
    },
    {
      code: 2,
      value: 'Severe'
    }
  ];

  


      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveHemodynamicInformation as SaveToolbar;
}

public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: HemodynamicInformationForm = {
        test_date: this.editState.test_date,
        test_type: this.editState.test_type,
        use_continuous_monitoring: this.editState.use_continuous_monitoring,
        continuous_monitoring_startdate: this.editState.continuous_monitoring_startdate,
        systolic_pa_pressure: this.editState.systolic_pa_pressure,
        diastolic_pa_pressure: this.editState.diastolic_pa_pressure,
        mean_pa_pressure: this.editState.mean_pa_pressure,
        pcw_pressure: this.editState.pcw_pressure,
        cardiac_index: this.editState.cardiac_index,
        cardiac_output: this.editState.cardiac_output,
        pvr_woods: this.editState.pvr_woods,
        pvr_reactivity: this.editState.pvr_reactivity,
        thermal_dilution: this.editState.thermal_dilution,
        transpulmonary_gradient: this.editState.transpulmonary_gradient,
        cardiac_catheter: this.editState.cardiac_catheter,
        cardiac_catheter_disease: this.editState.cardiac_catheter_disease,
        cardiac_catheter_test_date: this.editState.cardiac_catheter_test_date
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 

  public clearForm(): any {
    this.editState.test_date = null;
    this.editState.test_type = '';
    this.editState.use_continuous_monitoring = false;
    this.editState.continuous_monitoring_startdate = null;
    this.editState.systolic_pa_pressure = null;
    this.editState.diastolic_pa_pressure = null;
    this.editState.mean_pa_pressure = null;
    this.editState.pcw_pressure = null;
    this.editState.cardiac_catheter = false;
    this.editState.cardiac_catheter_disease = '';
    this.editState.cardiac_catheter_test_date = null;
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveHemodynamicInformation as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>
