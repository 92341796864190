<template>
  <div v-if="isProtoSchedulingPreferencesEnabled">
    <card-section
      section-id="proto_scheduling_preferences">
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="scheduling-preferences-section-form">
          <template v-slot:contents>
            <sub-section 
              :title="title" 
              sub-section-id="scheduling-preferences-section-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                  <div class="col-sm-12">
                    <fieldset>
                      <small class="w-100 d-flex mb-4">Does the patient have any preferences for when to schedule appointments?</small>
                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <div id="wrapper-scheduling">

                            <div class="table-wrapper">
                                  
                              <table class="table table-sm table-bordered">
                                <tbody>
                                  <tr>
                                    <th scope="row" class="sp-th-row-heading th-row">
                                      <td width="12.5%"  class="empty-cell" />
                                      <td width="12.5%" scope="column" class="sp-th-row-heading" v-for="item in editState.schedulingPrefs" :key="item.id">
                                        {{item.label}}
                                      </td>
                                    </th>
                                  </tr>
                            
                                    <tr scope="row" class="scheduling-row">
                                      <td width="12.5%">
                                        <span class="empty-label">AM</span>
                                      </td>
                                      <td width="12.5%" :key="index" v-for="(item, index) in editState.schedulingPrefs">
                                        <span class="input-padding" :class="item.availability_am.value ? `prefs-${(getSchedulingValue(item.availability_am.value)).toLowerCase()}` : ''">
                                          <select-input
                                            select-id="recipientAttachment-category"
                                            hideLabel="true"
                                            :name='item.availability_am.value'
                                            :options="schedulingOptions"
                                            v-model="item.availability_am.value"
                                            @change="inputChanged('am', item.availability_am, index)"
                                          >
                                          </select-input>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr scope="row" class="scheduling-row">
                                      <td width="12.5%">
                                        <span class="empty-label">PM</span>
                                      </td>
                                      <td width="12.5%" v-for="(item, index) in editState.schedulingPrefs" :key="index">
                                        <span
                                          class="input-padding" :class="item.availability_pm.value ? `prefs-${(getSchedulingValue(item.availability_pm.value)).toLowerCase()}` : ''">
                                          <select-input
                                            select-id="recipientAttachment-category"
                                            hideLabel="true"
                                            :name='item.availability_pm.value'
                                            :options="schedulingOptions"
                                            v-model="item.availability_pm.value"
                                            @change="inputChanged('pm', item.availability_pm, index)"
                                          >
                                          </select-input>
                                        </span>
                                      </td>
                                    </tr>
                                  

                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row"> 
                        <div class="standard-form-group form-group-checkbox">
                            <checkbox-input            
                              name="employment"
                              labelName="Employment"
                              inputId="employment"
                              v-model="editState.employment"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group form-group-checkbox">

                          <select-input
                              select-id="dialysis_modality"
                              name='Dialysis Modality'
                              :options="dialysisModalityOptions"
                              v-model="editState.dialysis_modality"
                            >
                            </select-input>
                          </div>
                      </div>
                      <div class="row">
                        <div class="standard-form-group-large">
                          <text-area-input input-id="scheduling-comments" name="Comments"
                            rows="4"
                            v-model="editState.comments" />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="saveSchedulingPreferences"
              @save="performSave"
              :label="`Save ${title}`"
              :cancelButton="true"        
            />
          </template>
          
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import DateInput from '@/components/shared/DateInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';

  const EDITSTATE_KEY = 'editState';

  interface SchedulingAvailability {
    id: string;
    value: string;
    isSet: boolean;
  }

  interface SchedulingPrefItem {
    id: string;
    dayOfWeek: number;
    label: string;
    availability_am: SchedulingAvailability;
    availability_pm: SchedulingAvailability;
  }

  interface SchedulingPreferencesForm {
    employment: string;
    dialysis_modality: string;
    comments: string;
    schedulingPrefs: SchedulingPrefItem[];
  }

  @Component({
    components: {
      CardSection,
      SubSection,
      SaveToolbar,
      SelectInput,
      DateInput,
      CheckboxInput,
      TextAreaInput,
      FormLayout
    },
  })
  export default class ProtoSchedulingPreferences extends Vue {
    
    private title: any = "Scheduling Preferences";

    private schedulingOptions = [{
      code: 0,
      value: 'Preferred'
    }, {
      code: 1,
      value: 'Available'
    }, {
      code: 2,
      value: 'Avoid'
    }, {
      code: 3,
      value: 'Dialysis'
    }];


    private dialysisModalityOptions = [{
      code: 0,
      value: 'HHD'
    }, {
      code: 1,
      value: 'ICHD'
    }, {
      code: 2,
      value: 'PD'
    }];



    public getSchedulingValue (item: any) {
      if (item) {
       return (this.schedulingOptions[item as any].value).toLowerCase();
      }
    }

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    // Put all form fields in a single editState object
    private editState: SchedulingPreferencesForm = {
      employment: '',
      dialysis_modality: '',
      comments: '',
      schedulingPrefs: [
        {
          id: 'mon',
          dayOfWeek: 1,
          label: 'Mon',
          availability_am: {
            id: "mon_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "mon_pm",
            value: '',
            isSet: false
          }
        },
        {
          id: 'tue',
          dayOfWeek: 2,
          label: 'Tue',
          availability_am: {
            id: "tue_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "tue_pm",
            value: '',
            isSet: false
          }
        },
        {
          id: 'wed',
          dayOfWeek: 3,
          label: 'Wed',
          availability_am: {
            id: "wed_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "wed_pm",
            value: '',
            isSet: false
          }
        },
        {
          id: 'thu',
          dayOfWeek: 4,
          label: 'Thu',
          availability_am: {
            id: "thu_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "thu_pm",
            value: '',
            isSet: false
          }
        },
        {
          id: 'fri',
          dayOfWeek: 5,
          label: 'Fri',
          availability_am: {
            id: "fri_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "fri_pm",
            value: '',
            isSet: false
          }
        }, {
          id: 'sat',
          dayOfWeek: 6,
          label: 'Sat',
          availability_am: {
            id: "sat_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "sat_pm",
            value: '',
            isSet: false
          }
        },
        {
          id: 'sun',
          dayOfWeek: 7,
          label: 'Sun',
          availability_am: {
            id: "sun_am",
            value: '',
            isSet: false
          },
          availability_pm: {
            id: "sun_pm",
            value: '',
            isSet: false
          }

        },

      ],
    };


  inputChanged(type: string, item: any, index: any) {
    let newItem = item;

    if (type == 'am') {
      this.editState.schedulingPrefs[index].availability_am = newItem;
    } else {
      this.editState.schedulingPrefs[index].availability_pm = newItem;
    }

  }

  get isProtoSchedulingPreferencesEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SchedulingPreferences);
    }

    // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
    async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.SchedulingPreferences, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: SchedulingPreferencesForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        employment: response.employment,
        dialysis_modality: response.dialysis_modality,
        comments: response.comments,
        schedulingPrefs: response.schedulingPrefs,
      };
    }


    // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.saveSchedulingPreferences as SaveToolbar;
    }

  public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: SchedulingPreferencesForm = {
        employment: this.editState.employment,
        dialysis_modality: this.editState.dialysis_modality,
        comments: this.editState.comments,
        schedulingPrefs: this.editState.schedulingPrefs
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  }
}



</script>



<style scoped>
  .empty-cell {
    height: 2rem;
  }

  #wrapper-scheduling {
    overflow: auto;
  }

  #wrapper-scheduling .table-bordered th.sp-th-row-heading {
    border: none;
    background: white;
    padding: 0;
    display: contents;
  }

  #wrapper-scheduling .table-bordered tr {
    border: none;
    padding: 0;
  }

  #wrapper-scheduling .sp-th-row-heading td {
    width: 12.5%;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  
  }

  #wrapper-scheduling .scheduling-row td {
    padding: 0;
    /* border: none; */
  }

  #wrapper-scheduling .input-padding {
    padding: 0.3rem;
    display: flex;
    /* background-color: #72f07b; */
    /* border: 1px solid #dee2e6; */
  }

  #wrapper-scheduling .input-padding span {
     width: 100%;
     min-width: 100px;
    }

    #wrapper-scheduling .empty-label {
    padding-top: 0.7rem;
    min-width: 100px;
    display: flex;
    font-weight: bold;
    justify-content: center;
   }


   .prefs-preferred {
    background-color: #72f07b;
   }

   .prefs-available {
    background-color: #bff8c3;
   }

   .prefs-avoid {
    background-color: #f8c3bf;
   }

  .prefs-dialysis {
    background-color: #f29791;
   }


scroll-shadow {
  overflow: auto;
  width: 100%;
	display: inline-block;
	--scroll-shadow-size: 14;
	--scroll-shadow-left: linear-gradient(to left, transparent 0%, #0003 100%);
	--scroll-shadow-right:  linear-gradient(to right, transparent 0%, #0003 100%);
}





  

</style>
