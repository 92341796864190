<template>
  <div>
    <template v-if="!dispatchEventsComplete">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
       <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-donor-page />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: getDonorsUrl }">
          {{ $t('donors') }}
        </router-link>
        / {{deceasedDonorDisplayName}}
      </page-top>
      <donor-sticky-summary
        ref="donorStickySummary"
        @loaded="(ref) => loaded(ref) "
        :openNowModal="() => openNowModal()"
      />
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-deceased-donor />

            <div class="page-content donor-profile">
              <validation-observer ref="validations" autocomplete="off" tag="form" @submit.prevent>
                <donor-summary
                  ref="donorSummary"
                  @loaded="(ref) => loaded(ref)"
                  :openNowModal="() => openNowModal()"
                />

                <donor-data-history
                  ref="donorDataHistory"
                  v-show="showDonorDataHistory"
                />

                <referral-information
                  ref="referralInformation"
                  @loaded="(ref) => loaded(ref)"
                  @reload="reload"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="isGroupWriteable('donor_personal')"
                />

                <donation-information
                  ref="donationInformation"
                  @loaded="(ref) => loaded(ref)"
                  @reload="reload"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="isGroupWriteable('donor_medical')"
                />

                <clinical-information
                  ref="clinicalInformation"
                  @loaded="(ref) => loaded(ref)"
                  @reload="reload"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="isGroupWriteable('donor_medical')"
                />

                <donor-documents
                  ref="donorDocuments"
                  v-if="groupExists('donor_attachments')"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                />

                <virology-results
                  ref="virology"
                  :newRecord="false"
                  :virologyType="virologyType"
                  @loaded="(ref) => loaded(ref)"
                  @reload="reload"
                  @handleErrors="(errors) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                  :canSave="isGroupWriteable('donor_medical')"
                />

                <organ-packaging-forms
                  ref="organPackagingForms"
                  v-if="donorOrganPackagingForms.length > 0 && (groupExists('organ_packaging') || groupExists('organ_packaging_odsif'))"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="groupExists('organ_packaging') || groupExists('organ_packaging_odsif')"
                />

                <recovery-details
                  ref="organRecoveryDetails"
                  v-if="groupExists('donor_recovery') || groupExists('donor_recovery_date')"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                  :canSave="isGroupWriteable('donor_recovery') || isGroupWriteable('donor_recovery_date')"
                />

                <!-- Monitor Recovery Outcomes i.e. Donor Summary -->
                <monitor-recovery-outcomes
                  ref="monitorRecoveryOutcomes"
                  v-if="showMonitorRecoveryOutcomes"
                  @loaded="(ref) => loaded(ref)"
                />

                <!-- Exceptional Distribution Disposition i.e. confirm recipient consent for ExD organ -->
                <exceptional-distribution-disposition
                  ref="exdConfirmationForm"
                  v-if="showExdDisposition"
                  @loaded="(ref) => loaded(ref)"
                />

                <hla-typing
                  ref="hlaTypingDonor"
                  :enable-donor="true"
                  @loaded="(ref) => loaded(ref)"
                  @reload="reload"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  :canSave="checkAllowed('/donors/:donor_id/hla_typing', 'POST')"
                />

                <serum-crossmatch-section
                  ref="serumCrossmatch"
                  :enable-donor="true"
                  @loaded="(ref) => loaded(ref)"
                  @save="(details) => handleSectionSave(details)"
                  @handleErrors="(errors) => handleErrors(errors)"
                  @clear="resetValidationErrors"
                />

                <div class="form-btns">
                  <save-toolbar
                    ref="saveDonor"
                    :label="$t('save_donor')"
                    :show="canSaveGetter(false)"
                    @save="performSave"
                  />
                </div>
              </validation-observer>
            </div>
          </div>
        </div>
        <national-organ-waitlist-modal ref="nationalOrganWaitlistModal" v-if="canAccessNowList" />
      </div>
    </template>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { VirologyType } from '@/store/labs/types';
import PageTop from '@/components/shared/PageTop.vue';
import HlaTyping from '@/components/hla/HlaTyping.vue';
import { Component } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveProvider, SaveResult } from '@/types';
import DonorSummary from '@/components/deceasedDonors/DonorSummary.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import RecoveryDetails from '@/components/deceasedDonors/_RecoveryDetails.vue';
import DonorDocuments from '@/components/deceasedDonors/DonorDocuments.vue';
import DonorDataHistory from '@/components/deceasedDonors/DonorDataHistory.vue';
import OrganPackagingForms from '@/components/deceasedDonors/OrganPackagingForms.vue';
import DonationInformation from '@/components/deceasedDonors/DonationInformation.vue';
import ReferralInformation from '@/components/deceasedDonors/ReferralInformation.vue';
import ClinicalInformation from '@/components/deceasedDonors/ClinicalInformation.vue';
import { DeceasedDonor, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import MonitorRecoveryOutcomes from '@/components/deceasedDonors/MonitorRecoveryOutcomes.vue';
import SideNavDeceasedDonor from '@/components/deceasedDonors/side-nav/SideNavDeceasedDonor.vue';
import DonorStickySummary from '@/components/deceasedDonors/DonorStickySummary.vue';
import SerumCrossmatchSection from '@/components/deceasedDonors/_SerumCrossmatchSection.vue';
import ExceptionalDistributionDisposition from '@/components/deceasedDonors/ExceptionalDistributionDisposition.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/loading-skeletons/LoadingDonorPage.vue';
import NationalOrganWaitlistModal from '@/components/shared/NationalOrganWaitlistModal.vue';
import { DONOR_DATA_HISTORY_ENABLED } from "@/store/administration/types";
import { setPageTitle } from '@/stores/currentPage';

@Component({
  components: {
    PageTop,
    HlaTyping,
    SaveToolbar,
    DonorSummary,
    RecoveryDetails,
    DonorDocuments,
    VirologyResults,
    DonorDataHistory,
    OrganPackagingForms,
    DonationInformation,
    ReferralInformation,
    ClinicalInformation,
    SideNavDeceasedDonor,
    DonorStickySummary,
    SerumCrossmatchSection,
    MonitorRecoveryOutcomes,
    ExceptionalDistributionDisposition,
    LoadingSideNav,
    LoadingDonorPage,
    NationalOrganWaitlistModal
  },
})
export default class EditDeceasedDonor extends mixins(ValidationUtilsMixin) implements SaveProvider {
  // State
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  // Getters
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('deceasedDonorDisplayName', { namespace: 'deceasedDonors' }) private deceasedDonorDisplayName!: string;
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('donorOrganPackagingForms', { namespace: 'deceasedDonors'}) private donorOrganPackagingForms!: any[];
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('canAccessNowList', { namespace: 'users' }) private canAccessNowList!: boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded: any = new Set();
  private allSectionsLoaded = false;

  /**
   * Returns enum value for VirologyType.DeceasedDonor
   *
   * @returns {number} enum value for Donor
   */
  get virologyType(): number {
    return VirologyType.DeceasedDonor;
  }

  get nowReference(): any {
    if(!this.$refs) return;

    this.$refs.nationalOrganWaitlist;
  }

  // Return true if all sections and their associated data has been loaded
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  // Should we show the Donor Data History card section?
  get showDonorDataHistory(): boolean {
    return DONOR_DATA_HISTORY_ENABLED && !this.isTransplantCoordinator;
  }

  public loaded(ref: string): void {
    if (!ref) return;

    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveDonor|donorDataHistory/)));

    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    // if page loaded, scroll to section
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  // Vue lifecycle hooks
  mounted(): void {
    const donorIdentifier = this.clientId;
    setPageTitle(this.$t('page.donor.edit', { donor: this.deceasedDonorDisplayName })),
    Promise.all([
      this.$store.dispatch('validations/loadEdit', { view: 'donors', action: 'edit', clientId: donorIdentifier }),
      this.$store.dispatch('allocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveDonor as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const referralInformation = this.$refs.referralInformation as ReferralInformation;
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    const clinicalInformation = this.$refs.clinicalInformation as ClinicalInformation;
    const virology = this.$refs.virology as VirologyResults;
    // Get donor patch from each form section
    const referralInfoPatch: DeceasedDonor = referralInformation.extractPatch();
    const donationInfoPatch: DeceasedDonor = donationInformation.extractPatch();
    const clinicalInfoPatch: DeceasedDonor = clinicalInformation.extractPatch();
    // Combine donor patches
    const donorPatch: DeceasedDonor = {
      ...referralInfoPatch,
      ...donationInfoPatch,
      ...clinicalInfoPatch,
    };
    // Merge the indicators from referral information and donation information
    donorPatch.indicators = Object.assign(referralInfoPatch.indicators || {}, donationInfoPatch.indicators);
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      donorPatch.virology_labs = [virology.extractPatch()];
    }
    // Special case: assign 'death' directly, because it has fields in both Donation Info and Clinical Info
    const deathPatch = clinicalInfoPatch.death || {};
    Object.assign(deathPatch, donationInfoPatch.death);
    Object.assign(donorPatch, { death: deathPatch });
    // Clear any save toolbar messages
    referralInformation.resetSaveToolbar();
    donationInformation.resetSaveToolbar();
    clinicalInformation.resetSaveToolbar();
    // Clear previous errors
    this.resetValidationErrors();
    // Attempt to save the recipient
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch}).then((success: SaveResult) => {
      this.registerSaveResult(success);
      this.reload();
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // Reload associated data that might change as a consequence of saving
  private reload(): void {
    // Reload Donor Data History
    const donorDataHistoryForm = this.$refs.donorDataHistory as DonorDataHistory;
    if (donorDataHistoryForm) donorDataHistoryForm.reload();

    // Reload ExD Disposition if applicable
    const exdConfirmationForm = this.$refs.exdConfirmationForm as ExceptionalDistributionDisposition;
    if (exdConfirmationForm) exdConfirmationForm.reload();
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditRecipient updates the currently selected recipient), zero records, or multiple records.
     */
    if (result.success) {
      this.$store.commit('deceasedDonors/set', result.responseData.donor);
    } else {
      // Handle errors
      this.handleErrors(result);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveDonor as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    this.resetValidationErrors();
    this.resetSaveToolbar();
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup: IdLookup = {
      ...(this.$refs.referralInformation as ReferralInformation).idLookup,
      ...(this.$refs.donationInformation as DonationInformation).idLookup,
      ...(this.$refs.clinicalInformation as ClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup(),
      ...(this.$refs.hlaTypingDonor as HlaTyping).idLookup(),
      ...(this.$refs.serumCrossmatch as SerumCrossmatchSection).idLookup()
    };
    if (this.groupExists('donor_attachments')) {
     Object.assign(idLookup,(this.$refs.donorDocuments as DonorDocuments).idLookup);
    }

    // Ensure errors are handled, even if user does not have access to one or more forms
    const organRecoveryDetails = this.$refs.organRecoveryDetails as RecoveryDetails;
    if (organRecoveryDetails) {
     Object.assign(idLookup, organRecoveryDetails.idLookup());
    }

    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveDonor as SaveToolbar;
    // Prevent roles that don't have access to the save button from calling reset
    if (saveToolbar) saveToolbar.reset();
  }

  private openNowModal(): void {
    const nationalOrganWaitlistModal = this.$refs.nationalOrganWaitlistModal as NationalOrganWaitlistModal;
    nationalOrganWaitlistModal.initialize();
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).setErrors({});
  }

  // Determine whether or not to show the Exceptional Distribution Disposition / Confirmations section
  get showExdDisposition(): boolean {
    const donorHasExdIndicator = !!this.donor?.indicators?.exceptional_distribution;
    return donorHasExdIndicator;
  }

  // Determine whether or not to show the Monitor Recovery Outcomes section
  // - by having the group monitor_recovery_outcomes & using a consented organ
  get showMonitorRecoveryOutcomes(): boolean {
    const hasRecoveryOutcomes = this.groupExists('donor_recovery_outcomes');
    const hasConsentedOrgan = (this.selectedDonorConsentedOrganList || []).length > 0;
    return hasConsentedOrgan && hasRecoveryOutcomes;
  }
}
</script>
