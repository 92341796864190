export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province"])},
        "tissue_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissue Donor"])},
        "organ_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Donor"])},
        "unknown_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Donor"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
        "system_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System returned"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all matching items"])}
        },
        "empty_recipient_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot find any patients that match the selected filters"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle selection of this row"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "scroll_right_to_see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll right to see more"])},
        "donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors"])},
        "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
        "out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province Patients"])},
        "save_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Donor"])},
        "save_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Patient"])},
        "save_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Living Donor"])},
        "living_donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donor Information"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virology Results"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      },
      "fr": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "out_of_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province"])},
        "tissue_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissue Donor"])},
        "organ_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Donor"])},
        "unknown_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Donor"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau profil"])},
        "system_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System returned"])},
        "table": {
          "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
          "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
          "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])},
          "select_all_matching_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tous les éléments correspondants"])}
        },
        "empty_recipient_listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot find any patients that match the selected filters"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement en cours…"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionner"])},
        "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la sélection de cette ligne"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "scroll_right_to_see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites défiler vers la droite pour en voir plus"])},
        "donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs"])},
        "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs vivants"])},
        "out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients hors de province"])},
        "save_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder donneur"])},
        "save_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder patient"])},
        "save_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder donneur vivant"])},
        "living_donor_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le donneur vivant"])},
        "virology_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la virologie"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négative"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      },
      "en_tgln": {
        "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
        "save_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Recipient"])},
        "out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Province Recipients"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Allocation and Transplant System"])}
      },
      "fr_tgln": {
        "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
        "save_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Recipient"])},
        "out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients hors de province"])},
        "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'attribution et de transplantation d'organes"])}
      }
    }
  })
}
