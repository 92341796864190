<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:toolbar />
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>
          <router-link class="nav-link no-scroll" :title="$t('return_to_prev')" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'edit-recipient', params: { id: recipient.client_id }, hash: '#organ-referral' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_prev')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>
      <div class="side-nav-container">
        <!-- existing journey links -->
        <selected-recipient-journey-links
          v-for="journey in selectedRecipientJourneysList"
          :key="journey.id"
          @toggle="toggleSideNav"
          :journeyName="journey.name"
          :journeyId="journey.id"
          :organCode="journey.organCode"
        />
        <!-- new journey links -->
        <template v-if="newOrgan">
          <div class="nav-block-wrap">
            <ul class="nav flex-column nav-block active">
              <li class="nav-item">
                <span class="nav-link no-scroll">
                  <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav" />
                  {{ $t(newOrgan) }}
                </span>
              </li>
              <ul class="nav flex-column" v-show="show">
                <div>
                  <li class="nav-item" @toggle="toggleSideNav">
                    <a class="nav-link" href="#organ-details-section">{{ $t('journey_overview', { organ: $t(newOrgan+"1").toString() }) }}</a>
                  </li>
                </div>
              </ul>
            </ul>
          </div>
        </template>
        <hr>
        <selected-recipient-profile-links @toggle="toggleSideNav">
          <template v-slot:profile-return-link>
            <router-link class="no-scroll open" style="color: #2e2e2e !important" :to="{ name: 'edit-recipient', params: { id: recipient.client_id } }">
              {{$t('profile')}}
            </router-link>
            </template>
          </selected-recipient-profile-links>
        <hr>
        <selected-recipient-hla-links @toggle="toggleSideNav" />
      </div>
    </template>
  </base-side-nav>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SelectedRecipientJourneyLinks from '@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue';
import SelectedRecipientProfileLinks from '@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { i18nMessages } from '@/i18n';
import { UIJourneySummary } from '@/UIModels/journey/journeySummary';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientHlaLinks,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/organs/shared/_locales/common.json'),
    require('@/components/_locales/Organs.json'),
    require('@/components/organs/shared/_locales/SideNavJourney.json'),
    require('@/components/recipients/_locales/SelectedRecipientJourneyLinks.json'),
  ]),
})
export default class SideNavJourney extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  // Props
  @Prop({ default: false }) active!: boolean;
  @Prop({ default: null }) newOrgan!: string|null;
  @Prop({ default: null }) organCode!: number;
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) newJourney!: boolean;

  public show = true;
  public sideNavClosed = false;

  public toggleNav() {
    this.show = !this.show;
  }

  get selectedRecipientJourneysList(): UIJourneySummary[] {
    return useCurrentPageStore().currentRecipient?.selectedRecipientJourneysList || [];
  }

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  
}
</script>
