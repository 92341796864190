import { APIColumnPreferences } from '@/APIModels/user/preferences/types';

export const DEFAULT_RECIPIENT_COLUMN_PREFERENCES: string[] = [
  'transplant_centre',
  'client_id',
  'registration_date',
  'first_name',
  'last_name',
  'dob',
  'mrn',
  'sex',
  'blood_type',
  'organ_code',
  'journey_state',
  'workflow_step',
  'checklist_completion',
];

export const DEFAULT_DONOR_COLUMN_PREFERENCES: string[] = [
  'deceased_donor_id',
  'hospital_name',
  'national_id',
  'first_name',
  'last_name',
  'age',
  'sex',
  'cause_of_death',
  'donor_type',
  'organ_code',
  'ecd',
  'referral_type',
  'referral_date'
];

export const DEFAULT_LIVING_DONOR_COLUMN_PREFERENCES: string[] = [
  'living_donor_id',
  'first_name',
  'last_name',
  'dob',
  'insurance_plan',
  'insurance_number',
  'blood_type',
  'organ_consents_display'
];

export const DEFAULT_WAITLIST_COLUMN_PREFERENCES: string[] = [
  'client_id',
  'first_name',
  'last_name',
  'transplant_hospital_mrn',
  'blood_type',
  'parsed_cluster',
  'parsed_donor_type',
  'medical_status_code',
  'listing_date',
  'parsed_wait_days',
  'parsed_wait_years',
  'parsed_hold_medical',
  'parsed_hold_serum',
  'parsed_suspended',
  'transplant_hospital_identifier',
  'smc_score',
  'vad_implant_date',
  'parsed_vad_indication',
  'parsed_vad_side',
  'medical_status_secondary_code',
  'allocation_points',
  'cumulative_cpra',
  'dialysis_start_date',
  'first_infusion_date',
];

export const DEFAULT_COLUMN_PREFERENCES: APIColumnPreferences = {
  'recipients': DEFAULT_RECIPIENT_COLUMN_PREFERENCES,
  'donors': DEFAULT_DONOR_COLUMN_PREFERENCES,
  'living_donors': DEFAULT_LIVING_DONOR_COLUMN_PREFERENCES,
  'waitlist': DEFAULT_WAITLIST_COLUMN_PREFERENCES,
};
