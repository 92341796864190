<template>
  <div v-if="isProtoCareGiversEnabled">
    <card-section
    section-id="proto_care_givers"
  >
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:body>
      <sub-section
        sub-section-id="proto-care-givers-section"
        :title="title"
      >
      <template v-slot:contents>
        
        <!-- Filter Bar + Create Button -->
        <table-toolbar
          :createButton="true"
          createText="Create Care Giver"
          @table-create-row="clearForm"
        >
          <template v-slot:button-bar>
            <div class="filter-section-action-row">
              <div class="filter-section-wrapper">
                <filter-component
                  fieldID="contactFilter"
                  :showFilter="true"
                  :showArchived="true"
                  @setFilters="handleSetFilters"
                />
              </div>
            </div>
          </template>
        </table-toolbar>

        <!-- List of Items, or History List -->
        <table-list
          ref="careGiversTable"
          tabbableColumn="lastUpdatedDisplay"
          table-id="care-givers-table"
          :table-config="careGiversTableConfig"
          @table-row-click="editSelected"
          :highlightSelection="true"
          :rowStyleClass="rowStyleClass"
        />

        <!-- Form layout -->
         <form>
          <form-layout
          form-id="recipientCareGiver-form">
            <template v-slot:title>
              <!-- Mode indicator / subsection form title -->
              <legend>
                <h5 v-if="isNew" class="legend-title">
                  New Care Giver
                </h5>
                <h5 v-else class="legend-title has-guiding-text">
                  Edit Care Giver
                </h5>
              </legend>
            </template>

            <template v-slot:action>
              <!-- Action Toolbar -->
              <action-toolbar
              ref="careGiversActionToolbar"
              :permittedActions="['create', 'update']"
              :isArchiving="isArchiving"
              :isRestoring="isRestoring"
              :loading="isLoadingActions"
              />
            </template>
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group">
                  <text-input rules="required" inputId="careGiver-name" name="Name" v-model="editState.name" :disabled="editState.status == 'archived'"/>
                </div>
                <div class="standard-form-group">
                  <text-input rules="required" inputId="careGiver-relationship" name="Relationship"
                    v-model="editState.relationship" :disabled="editState.status == 'archived'"/>
                </div>
                <div class="standard-form-group">
                  <checkbox-input name="Primary Care Giver?" labelName="Primary Care Giver?"
                    inputId="careGiver-primary-care" v-model="editState.isPrimary" label='Yes' :disabled="editState.status == 'archived'" />
                </div>
                <div class="standard-form-group">
                  <text-input rules="required" inputId="careGiver-location" name="Location" v-model="editState.location" :disabled="editState.status == 'archived'" />
                </div>
                </div>
                <div class="row">

                <div class="standard-form-group form-group-checkbox">
                  <label>Consented For</label>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="consent-appointments" hideLabel="true" labelName="Appointments"
                        inputId="consent-appointments" v-model="editState.consentAppointments" label='Appointments' :disabled="editState.status == 'archived'" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="consent-phi" hideLabel="true" labelName="PHI" inputId="consent-phi"
                        v-model="editState.consentPHI" label='PHI' :disabled="editState.status == 'archived'"/>
                    </div>
                  </div>
                </div>
                <div class="standard-form-group form-group-checkbox">
                  <label>Responsibility</label>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-finances" hideLabel="true" labelName="Budgeting / Finances"
                        inputId="responsibility-finances" v-model="editState.responsibilityFinances" label='Budgeting / Finances' :disabled="editState.status == 'archived'" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-reminders" hideLabel="true" labelName="Calendaring / Reminders"
                        inputId="responsibility-reminders" v-model="editState.responsibilityReminders"
                        label='Calendaring / Reminders' :disabled="editState.status == 'archived'" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-living-donor" hideLabel="true" labelName="Living Donor"
                        inputId="responsibility-living-donor" v-model="editState.responsibilityLivingDonor" label='Living Donor' :disabled="editState.status == 'archived'" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-medication" hideLabel="true" labelName="Medication"
                        inputId="responsibility-medication" v-model="editState.responsibilityMedication" label='Medication' :disabled="editState.status == 'archived'"/>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-transportation" hideLabel="true" labelName="Transportation"
                        inputId="responsibility-transportation" v-model="editState.responsibilityTransportation"
                        label='Transportation' :disabled="editState.status == 'archived'"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <checkbox-input name="responsibility-decision-making" hideLabel="true"
                        labelName="Understanding / Decision Making" inputId="responsibility-decision-making"
                        v-model="editState.responsibilityDecision" label='Understanding / Decision Making' :disabled="editState.status == 'archived'"/>
                    </div>
                  </div>
                </div>
                <div class="standard-form-group-large">
                  <text-area-input input-id="careGiver-comments" name="Comments" rows="4" v-model="editState.comments" :disabled="editState.status == 'archived'" />
                </div>
              </div>

            </template>
            <template v-slot:save>
              <save-toolbar
                :show="true"     
                ref="saveCareGiver"
                label="Save Care Giver Information"
                :cancelButton="true"
                @save="performSave()"
                @cancel="clearForm()"
              />
            </template>


          </form-layout>
        </form>
      </template>
  </sub-section>
    </template>
  </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import RecipientCareGiversColumns from '@/prototypes/json/RecipientCareGiversColumns.json';
  import SubSection from '@/components/shared/SubSection.vue';
  import TableToolbar from '@/components/shared/TableToolbar.vue';
  import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
  import TableList from '@/components/shared/TableList.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import ActionToolbar from '@/components/shared/ActionToolbar.vue';

  import {
    SaveProvider,
    TableConfig,
  } from '@/types';

  const PAGE_SIZES = [5, 10, 25];
  const EDITSTATE_KEY = 'editState';
  

  interface CareGiversForm {
    id: string,
    date: string,
    name: string,
    status: string,
    relationship: string,
    isPrimary: boolean,
    location: string,
    consentAppointments: string,
    consentPHI: string,
    responsibilityFinances: string,
    responsibilityReminders: string,    
    responsibilityLivingDonor: string,    
    responsibilityMedication: string,    
    responsibilityTransportation: string,    
    responsibilityDecision: string,    
    comments: string,
  }
  
  @Component({
    components: {
      CardSection,
      FilterComponent,
      SubSection,
      TableToolbar,
      TableList,
      TextInput,
      TextAreaInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout,
      ActionToolbar
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoCareGivers extends Vue {

    private title: any = "Care Givers";
    private isNew: any = true;
    private isArchiving = false;
    private isRestoring = false;
    private isLoadingActions = false;

    private editState: CareGiversForm = {
      id: '',
      date: '',
      name: '',
      status: 'active',
      relationship: "",
      isPrimary: false,
      location: '',
      consentAppointments: "",
      consentPHI: '',
      responsibilityFinances: "",
      responsibilityReminders: "",    
      responsibilityLivingDonor: "",    
      responsibilityMedication: "",    
      responsibilityTransportation: "",    
      responsibilityDecision: "",    
      comments: "",
    };

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    private careGivers: any = [];

    private isTableUpdating = false;
    private taskInProgress = false;
    public perPage = 10;
    public currentPageTable = 1;
    private isLoading = true;
    private isSubSectionLoading = true;
    private selectedItem = '';
    private emptyContactMethodForm = '';
    private displayFilter = true;
    private setFilters: any = {};
    private modalMode = ""
    private rows = [];
    private columns = RecipientCareGiversColumns

    private careGiverOptions = [{
      code: 0,
      value: 'Medicare'
    }, {
      code: 1,
      value: 'Commercial'
    }, {
      code: 2,
      value: 'Medicare Advantage'
    }, {
      code: 3,
      value: 'Medicaid'
    }];

    private portalOptions = [{
      code: 0,
      value: 'MyChart (Epic)'
    }, {
      code: 1,
      value: 'Other',
      other_selected: true,
    }]


    public categoryOptions = [{
      code: 0,
      value: 'Mobile Phone',
      type: 'phone'
    }, {
      code: 1,
      value: 'Home Phone',
      type: 'phone'
    }, {
      code: 2,
      value: 'Work Phone',
      type: 'phone'
    }, {
      code: 3,
      value: 'Email',
      type: 'email'
    }];

    private responsivenessOptions = [{
      code: 0,
      value: 'Good'
    }, {
      code: 1,
      value: 'Fair'
    }, {
      code: 2,
      value: 'Poor'
    }]

    get careGiversTableConfig(): TableConfig {
    return {
      data: this.tableRows || [],
      columns: this.columns,
      empty: 'use the form below to add a new row',
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  get isProtoCareGiversEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.CareGivers);
  }

        // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveCareGiver as SaveToolbar;
  }

  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
    // No-op if prototypes not enabled
    if (!useCurrentPageStore().configuration.prototypes) return;

    // Make a per-recipient prototype bucket
    this.protoBucket = new UIPrototypeBucket(PROTOTYPES.CareGivers, { recipientId: this.$route.params.id });

    // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
    const response: CareGiversForm = await this.protoBucket.getItem(EDITSTATE_KEY);
    if (!response) return;

    this.careGivers = response;
  }

  public getResponsibility(careGiver: any) {

    let result = [];

    for (const [key, value] of Object.entries(careGiver)) {
      if (key == "responsibilityFinances" || key == "responsibilityReminders" || key ==
        "responsibilityLivingDonor" || key == "responsibilityMedication" || key ==
        "responsibilityTransportation" || key == "responsibilityDecision") {
        if (key == "responsibilityFinances" && value == true) {
          result.push('Budgeting / Finances');
        } else if (key == "responsibilityReminders" && value == true) {
          result.push('Calendaring / Reminders');
        } else if (key == "responsibilityLivingDonor" && value == true) {
          result.push('Living Donor');
        } else if (key == "responsibilityMedication" && value == true) {
          result.push('Medication');
        } else if (key == "responsibilityTransportation" && value == true) {
          result.push('Transportation');
        } else if (key == "responsibilityDecision" && value == true) {
          result.push('Understanding / Decision Making');
        }
      }
    }

    if (result.length == 0) {
      result.push('-');
    }

    const codeHTML = `<ul class="${result[0] == '-'? "no-table-list-style" : 'table-list-style'}">` + result
      .reduce((html, item) => {
        return html + "<li>" + item + "</li>";
      }, "") + '</ul>';

    return codeHTML;
  }


  get tableRows() {
    const careGivers = this.careGivers || [];

    if (careGivers.length > 0) {

    return careGivers.map((careGiver: any) => {
      return {
        date: careGiver.date || '-',
        id: careGiver.id,
        name: careGiver.name,
        isPrimary: careGiver.isPrimary,
        relationship: careGiver.relationship,
        contactInformation: '-',
        contactMethods: careGiver.contactMethods,
        responsibility: this.getResponsibility(careGiver),
        location: careGiver.location,
        consentAppointments: careGiver.consentAppointments,
        appointmentsName: careGiver.consentAppointments ? 'Yes' : 'No',        
        consentPHI: careGiver.consentPHI,
        phiName: careGiver.consentPHI ? 'Yes' : 'No',
        responsibilityFinances: careGiver.responsibilityFinances,
        responsibilityReminders: careGiver.responsibilityReminders,
        responsibilityLivingDonor: careGiver.responsibilityLivingDonor,
        responsibilityMedication: careGiver.responsibilityMedication,
        responsibilityTransportation: careGiver.responsibilityTransportation,
        responsibilityDecision: careGiver.responsibilityDecision,
        comments: careGiver.comments || '-',
        status: careGiver.status,
        statusName: careGiver.status.charAt(0).toUpperCase() + careGiver.status.slice(1),
      };
    });
  }
  }

  public performSave(): any {
    // Call the 'startSaving' method to begin save animation
    if (this.saveToolbar) this.saveToolbar.startSaving();

    let careGivers = this.careGivers || [];

    const payload: CareGiversForm = {
      id: Math.random().toString(16).slice(2),
      date: new Date().toLocaleDateString('en-CA'),
      name: this.editState.name,
      status: this.editState.status,
      relationship: this.editState.relationship,
      isPrimary: this.editState.isPrimary,
      location: this.editState.location,
      consentAppointments: this.editState.consentAppointments,
      consentPHI: this.editState.consentPHI,
      responsibilityFinances: this.editState.responsibilityFinances,
      responsibilityReminders: this.editState.responsibilityReminders,    
      responsibilityLivingDonor: this.editState.responsibilityLivingDonor,    
      responsibilityMedication: this.editState.responsibilityMedication,    
      responsibilityTransportation: this.editState.responsibilityTransportation,    
      responsibilityDecision: this.editState.responsibilityDecision,  
      comments: this.editState.comments

    };

    if (!this.isNew ){  
      payload.id = this.editState.id;
      const foundIndex = careGivers.findIndex((reminder: any) => reminder.id == this.editState.id);
      
      if (foundIndex != -1) {
        careGivers[foundIndex] = payload;
      }
    } else {  
      careGivers.push(payload);
    }
    
    this.protoBucket.setItem(EDITSTATE_KEY, careGivers);

    // Call the 'showFakeSuccess' method to complete successful save animation
    if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    this.clearForm();
  } 


  private editSelected(row: any) {
      if (row) {  
        this.editState.id = row.row.id;
        this.editState.name = row.row.name;
        this.editState.relationship = row.row.relationship;
        this.editState.isPrimary = row.row.isPrimary;
        this.editState.status = row.row.status;
        this.editState.location = row.row.location;
        this.editState.consentAppointments = row.row.consentAppointments;
        this.editState.consentPHI = row.row.consentPHI;
        this.editState.responsibilityFinances = row.row.responsibilityFinances;
        this.editState.responsibilityReminders = row.row.responsibilityReminders;
        this.editState.responsibilityLivingDonor = row.row.responsibilityLivingDonor;
        this.editState.responsibilityMedication = row.row.responsibilityMedication;
        this.editState.responsibilityTransportation = row.row.responsibilityTransportation;
        this.editState.responsibilityDecision = row.row.responsibilityDecision;
        this.editState.comments = row.row.comments;
        this.isNew = false;
      }
    }

    public clearForm(): any {
      this.isNew = false;
      this.editState.name = '';
      this.editState.relationship = '';
      this.editState.location = '';
      this.editState.isPrimary = false;
      this.editState.status = 'active';
      this.editState.consentAppointments = '';
      this.editState.consentPHI = '';
      this.editState.responsibilityFinances = '';
      this.editState.responsibilityReminders = '';
      this.editState.responsibilityLivingDonor = '';
      this.editState.responsibilityMedication = '';
      this.editState.responsibilityTransportation = '';
      this.editState.responsibilityDecision = '';
      this.editState.comments = '';
    }

    rowStyleClass(row: any) {
    // if row editable, change color
    return row.editable ? 'tr-highlight-green' : null;
  }

    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.recipientCareGiver as unknown as SaveProvider;

      //const saveToolbar = (this.$refs.recipientCareGiver as SubSection).$refs[
      ///'save-recipient-careGiver'] as SaveToolbar;
      saveToolbar.resetSaveToolbar();
    }

    private handleSetFilters(): void {
    // Object.assign(this.filterAssignments, { ...filterAssignments });
  }


    

      // Clear selection based on a create button click event
  // private handleTableCreateRow(): void {
  //   this.careGiversTable.resetSelection();
  //   this.selection = new UIListFormSelection();
  // }

 
  }



</script>

<style>
  ul.table-list-style {
    -webkit-padding-start: 1rem;
    padding-inline-start: 1rem;
}

ul.no-table-list-style {
  list-style: none;
  padding-inline-start: 0;
}

</style>
