<template>
  <form v-if="isProtoLiverInformationEnabled">
    <sub-section
      sub-section-id="proto-liver-information-section"
      :title="title"
    >
    <template v-slot:contents>
    
      <!-- Form layout -->
     
        <form-layout
        form-id="sodiemMeld-form">
          <template v-slot:contents>
            <div class="row">
              <div class="standard-form-group">
                <checkbox-input 
                  name="vessels-required" 
                  labelName="Vessels Required?"
                  inputId="vessels-required" 
                  v-model="editState.vessels_required" 
                  label='Yes' />
              </div>

              <div class="standard-form-group">
                <checkbox-input 
                  name="bridging-therapy" 
                  labelName="Bridging Therapy?"
                  inputId="bridging-therapy" 
                  v-model="editState.bridging_therapy" 
                  label='Yes' />
              </div>

              <div class="standard-form-group">
                <checkbox-input 
                  name="downstaging-therapy" 
                  labelName="Downstaging Therapy?"
                  inputId="downstaging-therapy" 
                  v-model="editState.downstaging_therapy" 
                  label='Yes' />
              </div>
       
          <div class="standard-form-group-large">
              <text-area-input input-id="liver-info-comments" name="General Comments" rows="4" v-model="editState.comments"  />
            </div>
          </div>
   

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveLiverInformation"
            label="Save Liver Information"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>


      </form-layout>
      
    </template>
  </sub-section>
</form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SubSection from '@/components/shared/SubSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';

import {
  SaveProvider,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface InformationListForm {
  vessels_required: boolean,
  bridging_therapy: boolean,
  downstaging_therapy: boolean
  comments: string
}

@Component({
  components: {
    SubSection,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoLiverInformation extends Vue{

  private title: any = "Liver Information";

  private editState: InformationListForm = {
    vessels_required: false,
    bridging_therapy: false,
    downstaging_therapy: false,
    comments: ""
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  get isProtoLiverInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.LiverInformation);
  }


  private informationList: any = [];

        // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveLiverInformation as SaveToolbar;
  }

  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.LiverInformation, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: InformationListForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        vessels_required: response.vessels_required,
        bridging_therapy: response.bridging_therapy,
        downstaging_therapy: response.downstaging_therapy,
        comments: response.comments
      };
    }

  public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: InformationListForm = {
        vessels_required: this.editState.vessels_required,
        bridging_therapy: this.editState.bridging_therapy,
        downstaging_therapy: this.editState.downstaging_therapy,
        comments: this.editState.comments
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 


    public clearForm(): any {
      this.editState.vessels_required = false;
      this.editState.bridging_therapy = false;
      this.editState.downstaging_therapy = false;
      this.editState.comments = "";
    }


    public resetSaveToolbar(): void {
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveLiverInformation as unknown as SaveProvider;

      saveToolbar.resetSaveToolbar();
    }
    
 }



</script>

<style>


</style>
