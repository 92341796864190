<template>
  <div>
    <page-top>
      {{ $t("living_donors")}}
    </page-top>
    <template v-if="!livingDonors">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-list-view />
        </div>
      </div>
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <nav class="nav action-row">
          <column-config
            columnKey="living_donors"
            config-id="living-donors-list-columns"
            :options="donorColumns"
            v-model="columnsFilter.selectedColumns"
          />
          <router-link class="btn btn-primary ml-auto" :class="{ disabled: !canCreate }" :to="{ name: 'new-living-donor' }">
            {{ $t("add_new_living_donor") }}
          </router-link>
        </nav>
        <template>
          <p>{{ $t("system_returned")}} <b>{{livingDonors.count}}</b> {{ $t("living_donors")}}</p>
          <div class="highlight-scroll" v-if="isOverflowed">
            {{ $t('scroll_right_to_see_more') }}
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
          </div>
          <sub-section
            sub-section-id="list-living-donors"
            title=""
            mode="remote"
            :tabbableValue="$t('living_donor_id')"
            :total-records="livingDonors.count"
            :lookupsToLoad="lookupsToLoad"
            :isParentLoading="isLoading"
            :table-config="donorTableConfig()"
            @on-page-change="updatePagination($event)"
            @on-per-page-change="updatePagination($event)"
            @on-column-filter="filterList($event)"
            @on-sort-change="filterList($event)"
            @table-row-click="selectLivingDonor($event)">
          </sub-section>
        </template>
      </template>
    </full-page-list-layout>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import PageTop from '@/components/shared/PageTop.vue';
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import { ListLivingDonor, LivingDonorOrgan, StateLivingDonorsList } from '@/store/livingDonors/types';
import { Component } from 'vue-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import { Sex, RhIndicator, BloodType, OrganCodeValue, Organ, InsurancePlanCode, Ethnicity } from "@/store/lookups/types";
import { urlParams, isMasked } from '@/utils';
import LoadingListView from '@/components/shared/loading-skeletons/LoadingListView.vue';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import { EP } from '@/api-endpoints';
import { GenericCodeValue } from "@/store/types";
import { i18nMessages } from "@/i18n";
import { useCurrentPageStore } from "@/stores/currentPage";

interface ColumnsFilterState {
  selectedColumns: string[];
}

@Component({
  components: {
    PageTop,
    ColumnConfig,
    SubSection,
    LoadingListView,
    FullPageListLayout
  },
  ...i18nMessages([
    require('../_locales/listLivingDonors.json'),
    require('../_locales/common.json'),
    require('@/components/_locales/Organs.json'),
  ]),
})
export default class ListLivingDonors extends mixins(DateUtilsMixin) {
  @State(state => state.livingDonors.livingDonorsList) livingDonors!: StateLivingDonorsList;
  @State(state => state.pageState.currentPage.columnsFilter) columnsFilter!: ColumnsFilterState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];

  // getters
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
  @Getter('ethnicity', { namespace: 'lookups' }) ethnicity!: Ethnicity[];
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('bloodTypesForLivingDonor', { namespace: 'lookups' }) private bloodTypesForLivingDonor!: BloodType[];
  @Getter('rhIndicatorsForLivingDonor', { namespace: 'lookups' }) private rhIndicatorsForLivingDonor!: RhIndicator[];

  get canCreate(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'POST');
  }

  public currentPage = 1;
  public perPage = 25;
  public isOverflowed = false;
  public isLoading = false;

  private lookupsToLoad = ['blood_type', 'rh_indicator', 'sex', 'insurance_plan_codes', 'ethnicity'];

  // Vue lifecycle hooks
  public mounted(): void {
    this.loadData();
    this.initializePageState();
    this.checkwidth();
    window.addEventListener('resize', this.checkwidth);
  }

  public checkwidth() {
    if (window.innerWidth < 1500) {
      this.isOverflowed = true;
    } else {
       this.isOverflowed = false;
    }
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'columnsFilter',
      value: this.buildColumnsFilter(),
    });
  }

  private buildColumnsFilter(): ColumnsFilterState {
    // get selected columns from user preferences or defaults
    const columns = useCurrentPageStore().preferences.getColumns('living_donors');

    return {
      selectedColumns: columns,
    };
  }

  public loadData(search='', sort='') {
    this.isLoading = true;
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'livingDonors/getList', {
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
      }
    ).then(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    });
  }

  get sexOptionsDropdownValues(): GenericCodeValue[] {
    return this.sexOptions.map((option: Sex): GenericCodeValue => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }



  get bloodTypeOptionsDropdownValues(): any[] {
    if(!this.bloodTypesForLivingDonor) {
      return [];
    }

    return this.bloodTypesForLivingDonor.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get rhIndicatorOptionsDropdownValues(): any[] {
    if(!this.rhIndicatorsForLivingDonor) {
      return [];
    }

    return this.rhIndicatorsForLivingDonor.filter((item: any) => !item.disabled).map((option: any) => {
      return {
        value: option.value,
        code: encodeURIComponent(option.code)
      };
    });
  }


  get filteredColumns(): any[] {
    if (!this.columnsFilter || !this.columnsFilter.selectedColumns) {
      return this.donorColumns;
    }
    const selectedFields = this.columnsFilter.selectedColumns;
    const selectedColumns = this.donorColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  get livingDonorsForTable(): ListLivingDonor[] {
    if (!this.livingDonors || this.isLoading || !this.insurancePlanCodeLookup) {
      return [];
    } else {
      const livingDonors: ListLivingDonor[] = this.livingDonors.entries || [];
      livingDonors.map((livingDonor: ListLivingDonor) => {
        const organsConsented = (livingDonor.organ_consents || []).filter((organ: LivingDonorOrgan) => organ.consented);
        const consentedOrganCodes = this.buildSingleTypeOrgans;
        const organConsentedNames = organsConsented.map((organ: LivingDonorOrgan) => {
          if (isMasked(organ.organ_code)) return null;
          const name: string|undefined = this.organName(organ.organ_code) || undefined;
          return name ? this.$t(name) : 'Unknown';
        });
        const kidney = organsConsented.find((organ: LivingDonorOrgan) => OrganCodeValue.Kidney === organ.organ_code);

        livingDonor.organ_consents_display = organConsentedNames.join('<br /> ');

        const insuranceName = livingDonor.insurance_plan_code ? this.lookupValue(livingDonor.insurance_plan_code.toString(), 'insurance_plan_codes') : '-';
        livingDonor.insurance_plan = insuranceName;

        const journeys = livingDonor.living_donor_info?.journeys;
        const registrationDate = journeys && journeys[0] ? journeys[0].registration_date : undefined;
        livingDonor.registration_date = registrationDate;

        return livingDonor;
      });
      return livingDonors;
    }
    return [];
  }

  /**
   * Return organs of type 'single'
   *
   * @returns {Organ[]} filtered list of Organs
   */
  get buildSingleTypeOrgans(): Organ[] {
    return this.organLookup.filter((organ: Organ) => {
      return organ.type === 'single';
    });
  }

  get donorColumns(): any[] {
    return [
      {
        label: `${this.$t('living_donor_id')}`,
        field: 'living_donor_id',
        sortable: true,
        width: '160px',
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('first_name')}`,
        field: 'first_name',
        sortable: true,
        width: '200px',
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('last_name')}`,
        field: 'last_name',
        sortable: true,
        width: '200px',
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('dob')}`,
        field: 'dob',
        type: 'date',
        sortable: false,
        width: '130px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        formatFn: this.formatDate
      },
      {
        label: `${this.$t('insurance_type')}`,
        field: 'insurance_plan',
        sortable: true,
        width: '130px',
        filterOptions: {
          enabled: true,
          custom: true,
          placeholder: this.$t('all'),
          type: 'dropdown',
          filterDropdownItems: this.insurancePlansOptions
        }
      },
      {
        label: `${this.$t('insurance_number')}`,
        field: 'insurance_number',
        sortable: true,
        width: '160px',
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'text'
        }
      },
      {
        label: `${this.$t('blood_type')}`,
        field: 'blood_type',
        thClass: 'vgt-small-column',
        tdClass: 'vgt-small-column',
        sortable: true,
        width: "150px",
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'dropdown',
          placeholder: this.$t('all'),
          filterDropdownItems: this.bloodTypeOptionsDropdownValues
        }
      },
      {
        label: `${this.$t('organ_consents_display')}`,
        field: 'organ_consents_display',
        html: true,
        sortable: true,
        width: '170px',
        filterOptions: {
          enabled: true,
          type: 'text',
          custom: true,
        }
      },
      {
        label: `${this.$t('living_donor_registration_date')}`,
        field: 'registration_date',
        sortable: false,
        width: '130px',
        formatFn: this.formatDateFromDateTime
      },
      {
        label: `${this.$t('sex')}`,
        field: 'sex',
        thClass: 'vgt-small-column',
        tdClass: 'vgt-small-column',
        sortable: true,
        width: "130px",
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'dropdown',
          placeholder: this.$t('all'),
          filterDropdownItems: this.sexOptionsDropdownValues
        }
      },
      {
        label: `${this.$t('ethnicity')}`,
        field: 'ethnicity',
        sortable: false,
        width: '160px',
        formatFn: this.formatEthnicity
      },
      {
        label: `${this.$t('rh_factor')}`,
        field: 'rh',
        width: '115px',
        sortable: true,
        filterOptions: {
          enabled: true,
          custom: true,
          type: 'dropdown',
          placeholder: this.$t('all'),
          filterDropdownItems: this.rhIndicatorOptionsDropdownValues
        }
      },
    ];
  }

  get insurancePlansOptions() {
    if ((this.insurancePlanCodeLookup || []).length <= 0) return [];
    return this.insurancePlanCodeLookup.map((plan) =>{
      return {
        value: plan.value,
        code: plan.value
      };
    });
  }

  formatEthnicity(value: number): string|undefined {
    const ethnicity = this.ethnicity.find((item: Ethnicity) => value === item.code);
    return ethnicity ? ethnicity.value : undefined;
  }

  /**
   * Parse date-only field to be shown in table
   *
   * @param value the date property
   * @returns {string} string representation of date
   */
  formatDate(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUi(value) || '-';
  }

  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
  formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  private donorTableConfig(): TableConfig {
    return {
      data: this.livingDonorsForTable,
      columns: this.filteredColumns,
      empty: 'Cannot find any Living Donors that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }

  public selectLivingDonor(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      this.$router.push({
        name: 'edit-living-donor',
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing donor clientId');
      this.$router.push({name: 'list-living-donors'});
    }
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;

    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }
}

</script>
