import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_page = _resolveComponent("loading-recipient-page")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.journey)
      ? (_openBlock(), _createBlock(_component_loading_recipient_page, { key: 0 }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ]))
}