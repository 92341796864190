<template>
  <div v-if="isProtoLiverSpecificDetailsEnabled">
  <card-section
    section-id="proto_liver_specific_details"
  >
    <template v-slot:header>
      Liver Specific Details
    </template>
    <template v-slot:body>
      <proto-liver-sodium-meld/>  
      <proto-liver-information/>
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ProtoLiverSodiumMeld from '@/prototypes/organs/liver/ProtoLiverSodiumMeld.vue';
import ProtoLiverInformation from '@/prototypes/organs/liver/ProtoLiverInformation.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';


@Component({
  components: {
    CardSection,
    ProtoLiverSodiumMeld,
    ProtoLiverInformation
  }
})
export default class ContactInformation extends Vue {


  get isProtoLiverSpecificDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.LiverSpecificDetails);
  }
}
</script>
