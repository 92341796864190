import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { APIRoute, EP } from "@/api-endpoints";
import { CoordinatorsState, Coordinator } from "@/store/coordinators/types";

export const actions: ActionTree<CoordinatorsState, RootState> = {
  load({ commit, rootGetters }, { hospitalId, key = 'filtered'}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.coordinators.index,);

      const opts: { params: { [key: string]: string } } = { params: {} };
      if (hospitalId) opts.params.hospital_id = hospitalId;

      axios.get(url, opts).then((response: any) => {
        const data: Coordinator[] = response.data.coordinators;
        const opts = { key, data };
        commit('set', opts);
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  },
  loadLivingDonorCoordinators({ commit, rootGetters }, { hospitalId, key = 'filtered'}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.coordinators.index_living_donors, [[':hospital_id', hospitalId]]);
      axios.get(url).then((response: any) => {
        const data: Coordinator[] = response.data.coordinator;
        const opts = { key, data };
        commit('set', opts);
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  }
};
