<template>
  <div v-if="configuration" :id="configuration.appBaseClass" :class="`site-wrap ${authenticationClass}`">
    <template v-if="configuration.loaded && !configuration.sanityCheck">
      <warning-banner/>
    </template>
    <template v-else>
      <!-- show application -->
      <template v-if="loaded">
        <site-header v-if="loggedIn" />
        <router-view role="main" />
        <site-footer v-if="loggedIn"/>
      </template>
      <template v-else>
        <div class="loading"/>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, mixins } from 'vue-facing-decorator';
import SiteHeader from './components/shared/SiteHeader.vue';
import SiteFooter from './components/shared/SiteFooter.vue';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import WarningBanner from '@/views/WarningBanner.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIPrototypes } from '@/UIModels/prototypes';
import { UIError } from '@/UIModels/error';

@Component({
  components: {
    SiteHeader,
    SiteFooter,
    WarningBanner
  }
})
export default class App extends mixins(TranslationUtilsMixin) {
  @Getter('hasAccessToken', { namespace: 'users' }) private hasAccessToken!: boolean;
  @Getter('isLoggedIn', { namespace: 'users' }) private isLoggedIn!: boolean;

  public loaded = false;
  public organTypes: [] = [];

  /**
   * Returns configuration object
   *
   * @returns {UIConfiguration}
   */
  get configuration(): UIConfiguration {
    return useCurrentPageStore().configuration as UIConfiguration;
  }

  /**
   * Returns prototypes object
   *
   * @returns {UIPrototypes}
   */
   get prototypes(): UIPrototypes {
    return useCurrentPageStore().prototypes as UIPrototypes;
  }

  /**
   * Returns css class whether logged-in / logged-out 
   *
   * @returns {string}
   */  
  get authenticationClass(): string {
    return this.isLoggedIn ? 'logged-in' : 'logged-out';
  }

  /**
   * true if on /login url
   *
   * @returns {boolean}
   */
  get onLoginPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/login') ? true : false;
  }

  /**
   * true if on /access_token url
   *
   * @returns {boolean}
   */
  get onAccessTokenPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/access_token') ? true : false;
  }

  /**
   * true if logged in
   *
   * @returns {boolean}
   */
  get loggedIn(): boolean {
    return this.hasAccessToken && !this.onLoginPage && !this.onAccessTokenPage;
  }

  /**
   * If logged out, load the parts you need to login
   * After login the page will be refreshed so load all the parts to run the application
   */
  public async mounted(): Promise<void> {
    if (!this.loggedIn) {
      try {
        await this.configuration.load();
        await this.setupI18n();
        this.loaded = true;
      } catch (errorResponse: unknown) {
        console.warn(new UIError('app_vue', errorResponse));
        this.loaded = true;
      }
    } else {
      try {
        await this.$store.dispatch('users/loadUser');
        await this.configuration.load();
        await this.$store.dispatch('lookups/load', { lookup: 'organ' });
        this.prototypes.load(),
        await this.setupI18n();
        this.loaded = true;
      } catch (errorResponse: unknown) {
        console.warn(new UIError('app_vue', errorResponse));
        this.loaded = true;
      }
    }
  }
}
</script>
