<template>
  <div v-if="isProtoEgfrAndDiaylsisEnabled">
  <card-section
    section-id="proto_egfr_and_dialysis"
  >
    <template v-slot:header>
      eGFR and Dialysis
    </template>
    <template v-slot:body>
      <proto-egfr-section/>
      <proto-dialysis-events/>
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ProtoEgfrSection from '@/prototypes/profile/ProtoEgfrSection.vue';
import ProtoDialysisEvents from '@/prototypes/profile/ProtoDialysisEvents.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';


@Component({
  components: {
    CardSection,
    ProtoEgfrSection,
    ProtoDialysisEvents
  }
})
export default class ContactInformation extends Vue {


  get isProtoEgfrAndDiaylsisEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.EgfrAndDiaylsis);
  }
}
</script>
