<template>
  <loading-summary-card v-if="isLoadingLookups || !uiRecipientSummary" />
  <div class="card summary-card" v-else>
    <div class="card-header">
      <div class="media align-items-center">
        <div class="media-left">
          <font-awesome-icon :icon="['far', 'id-card']" fixed-width />
        </div>
        <div class="media-body flex-row align-items-center">
          <h3 class="summary-name">
            {{ uiRecipientSummary.summaryName }}
            <span v-if="uiRecipientSummary.summaryStatus">{{ $t(uiRecipientSummary.summaryStatus) }}</span>
          </h3>
          <ul class="nav card-nav">
            <li class="nav-item">
              <router-link :to="{ name: 'edit-recipient', params: { id: uiRecipientSummary.clientId } }" class="nav-link">{{ $t('recipient_information') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'recipient-hla' }" class="nav-link">{{ $t('hla_information') }}</router-link>
            </li>
            <li class="nav-item">
              <div class="dropdown">
                <new-organ-link :navlink="true"/>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="summary-list">
        <dl>
          <dt>{{ $t('client_id') }}</dt>
          <dd>{{ uiRecipientSummary.clientId }}</dd>
        </dl>
        <dl v-if="isDateOfBirthEnabled">
          <dt>{{ $t('dob') }}</dt>
          <dd>{{ uiRecipientSummary.dateOfBirth || '-' }}</dd>
        </dl>
        <dl v-if="isDateOfBirthEnabled">
          <dt>{{ $t('age') }}</dt>
          <dd>{{ uiRecipientSummary.age != null ? uiRecipientSummary.age : '-' }}</dd>
        </dl>
        <dl v-if="isSexEnabled">
          <dt>{{ $t('sex') }}</dt>
          <dd>{{ uiRecipientSummary.sex || '-' }}</dd>
        </dl>
        <dl v-if="isBloodTypeEnabled">
          <dt>{{ $t('abo') }}</dt>
          <dd>{{ uiRecipientSummary.bloodType || '-' }}</dd>
        </dl>
      </div>
      <hr v-if="uiRecipientSummary.activeJourneys.length > 0"/>

      <!-- Active Journeys -->
      <h5 class="secondary-title font-weight-bold" v-if="uiRecipientSummary.activeJourneys.length>0">{{ $t('active_journeys') }}</h5>
      <!-- The organ summary element -->
      <div class="summary-items" :aria-label="$t('active_journeys')">
          <!-- New format organ summary-->
          <div class="row">
            <div class="summary-journey-item summary-item" v-for="(uiJourneySummary, idx) in uiRecipientSummary.activeJourneys" :key="idx">
              <!-- Organ specific classes go here: "active", "summary-tile-ready", "summary-tile-in-progress". More than one at a time is fine-->
              <div :class="{ ...uiJourneySummary.summaryTileClass, active: $route.params.organ_id == uiJourneySummary.id }">
                <router-link class="organ-row" :to="{ name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }" >
                  <organ-icon :organ-code="uiJourneySummary.organCode" />
                  <div class="summary-organ">
                    {{ $t(journeyName(uiJourneySummary.organCode, uiJourneySummary.id)) }}
                  </div>
                </router-link>
                <!-- This whole name/value list has been replace so the values align vertically to make for easier reading -->
                <div class="organ-summary-wrap">
                  <dl class="organ-summary-list">
                    <dt>{{ $t('phase') }}:</dt>
                    <dd>
                      {{ lookupValue(uiJourneySummary.phase, 'recipient_journey_phase_status') }}
                      <span>{{ uiJourneySummary.phaseEntryDate }}</span>
                    </dd>
                  </dl>
                  <dl class="organ-summary-list">
                    <dt>{{ $t('status') }}:</dt>
                    <dd>{{ lookupValue(uiJourneySummary.status, `recipient_journey_phase_status/${uiJourneySummary.phase}/statuses`) }}</dd>
                  </dl>
                  <dl class="organ-summary-list" v-if="uiJourneySummary.reason">
                    <dt>{{ $t('reason') }}:</dt>
                    <dd>{{ lookupValue(uiJourneySummary.reason, `recipient_journey_phase_status/${uiJourneySummary.phase}/statuses/${uiJourneySummary.status}/reasons`) }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <!-- / New format organ summary -->

          <!-- Post-Transplant Journeys -->
          <hr v-if="uiRecipientSummary.postTransplantJourneys.length > 0"/>
          <h5 class="title" v-if="uiRecipientSummary.postTransplantJourneys.length > 0">{{ $t('post_transplant_journeys') }}</h5>
          <div class="summary-items" :aria-label="$t('post_transplant_journeys')">
              <!-- New format organ summary-->
              <div class="row">
                <div class="summary-journey-item summary-item" v-for="(uiJourneySummary, idx) in uiRecipientSummary.postTransplantJourneys" :key="idx">
                  <!-- Organ specific classes go here: "active", "summary-tile-ready", "summary-tile-in-progress". More than one at a time is fine-->
                  <div :class="{ ...uiJourneySummary.summaryTileClass, active: $route.params.organ_id == uiJourneySummary.id }">
                    <router-link :to="{ name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }" class="organ-row">
                      <organ-icon :organ-code="uiJourneySummary.organCode" />
                      <div class="summary-organ">
                        {{ $t(journeyName(uiJourneySummary.organCode, uiJourneySummary.id)) }}
                      </div>
                    </router-link>
                    <div class="organ-summary-wrap">
                      <dl class="organ-summary-list">
                        <dt>{{ $t('transplant_date') }}:</dt>
                        <dd>{{ uiJourneySummary.transplantedDate || '-' }} </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <!-- / New format organ summary -->
          </div>

          <!-- Closed Journeys -->
          <hr v-if="uiRecipientSummary.closedJourneys.length > 0"/>
          <h5 class="title" v-if="uiRecipientSummary.closedJourneys.length > 0">{{ $t('closed_journeys') }}</h5>
          <div class="summary-items" :aria-label="$t('closed_journeys')">
              <!-- New format organ summary-->
              <div class="row">
                <div class="summary-journey-item summary-item" v-for="(uiJourneySummary, idx) in uiRecipientSummary.closedJourneys" :key="idx">
                  <!-- Organ specific classes go here: "active", "summary-tile-ready", "summary-tile-in-progress". More than one at a time is fine-->
                  <div :class="{ ...uiJourneySummary.summaryTileClass, active: $route.params.organ_id == uiJourneySummary.id }">
                    <router-link :to="{ name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }" class="organ-row">
                      <organ-icon :organ-code="uiJourneySummary.organCode" />
                      <div class="summary-organ">
                        {{ $t(journeyName(uiJourneySummary.organCode, uiJourneySummary.id)) }}
                      </div>
                    </router-link>
                    <div class="organ-summary-wrap">
                      <dl class="organ-summary-list">
                        <dt>{{ $t('closed_date') }}:</dt>
                        <dd>{{ uiJourneySummary.closedDate || '-' }} </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <!-- / New format organ summary -->
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Prop } from 'vue-facing-decorator';
import { Organ } from '@/store/lookups/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NewOrganLink from '@/components/shared/NewOrganLink.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { i18nMessages } from "@/i18n";
import LoadingSummaryCard from '@/components/shared/loading-skeletons/LoadingSummaryCard.vue';
import { GenericCodeValue } from "@/store/types";
import { UIRecipientSummary } from "@/UIModels/recipients/recipientSummary";
import { UIRecipient } from "@/UIModels/recipient";
import { DATA_BUNDLES } from "@/UIModels/configuration/features";

@Component({
  components: {
    LoadingSummaryCard,
    OrganIcon,
    SubSection,
    NewOrganLink,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/RecipientSummary.json'),
    require('../_locales/Organs.json'),
    require('@/components/_locales/iposFields.json'),
  ]),
})
export default class RecipientSummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.recipient_journey_phase_status) phaseStatusLookup!: GenericCodeValue[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('organName', { namespace: 'lookups'}) organName!: (organCode?: number) => string;
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: () => { return []; } }) decisionDate!: {
    id: string | undefined;
    event_date: string;
  }[];

  private lookupsToLoad = [
    'recipient_journey_phase_status',
  ]

  private isLoadingLookups = true;

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  get uiRecipientSummary(): UIRecipientSummary|null {
    if (!this.uiRecipient) return null;

    return this.uiRecipient.summary as UIRecipientSummary;
  }

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isBloodTypeEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientGeneralClinical.bloodType.enabled;
  }

  public createJourney(organCode: string) {
    this.$router.push({
      name: 'new-organ',
      params: {
        id: String(this.uiRecipientSummary?.clientId),
        organ_code: organCode
      }
    });
  }

  mounted() {
    this.loadLookups(this.lookupsToLoad);
  }
  
  // PRIVATE

  private async loadLookups(lookupsToLoad: string[]): Promise<void> {
    this.isLoadingLookups = true;
    await Promise.all([
      ...lookupsToLoad.map((lookup: string): Promise<any> => { return this.$store.dispatch('lookups/queueLookup', { lookup }); }),
    ]);

    this.isLoadingLookups = false;
  }
}
</script>
