<template>
  <div>
    <page-top>
      <span class="skeleton-box" style="width: 200px" />
    </page-top>
    <div class="content-wrap">
      <div class="container-fluid">
        <div class="nav-wrapper">
          <loading-side-nav />
          <div class="page-content">
            <loading-summary-card />
            <loading-recipient-sections />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import PageTop from '@/components/shared/PageTop.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import LoadingSummaryCard from '@/components/shared/loading-skeletons/LoadingSummaryCard.vue';
import LoadingRecipientSections from '@/components/shared/loading-skeletons/LoadingRecipientSections.vue';

@Component({
  components: {
    PageTop,
    LoadingSideNav,
    LoadingSummaryCard,
    LoadingRecipientSections,
  }
})
export default class LoadingRecipientPage extends Vue {}
</script>
