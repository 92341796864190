<template>
  <login-container>
    <template v-slot:content>
      <div class="form-group">
        <template v-if="showUserSelect">
          <template v-if="bypassFusionAuth">
            <user-list :fusionAuth="!bypassFusionAuth"/>
          </template>
          <template v-else>
            <form :action="fusionAuthURL" v-on:submit="(event) => prepareRequest('fusionauth')" ref="fusionRequest" method="post" class="oneIdRequest">
              <input type="submit" :value="$t('login_fusionauth')" class="btn btn-info login float-left"/>
            </form>
          </template>
        </template>
        <login-google-workspace v-if="loginWithGoogleWorkspace"/>
      </div>
    </template>
  </login-container>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator';
import { APINakedRoute, EP } from '@/api-endpoints';
import LoginContainer from '@/components/shared/LoginContainer.vue';
import UserList from '@/components/login/UserList.vue';
import LoginGoogleWorkspace from '@/components/shared/LoginGoogleWorkspace.vue';
import { UrlUtilsMixin } from "@/mixins/url-utils-mixin";
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    LoginContainer,
    UserList,
    LoginGoogleWorkspace
  },
  ...i18nMessages([
    require('@/components/login/_locales/common.json'),
  ]),
})
export default class Login extends mixins(UrlUtilsMixin) {
  private mounted(): void {
    localStorage.removeItem('user_token');
  }

  /**
   * get fusionauth url
   *
   * @returns string
   */
  get fusionAuthURL(): string { return APINakedRoute(EP.fusionAuth.request); }

  get showUserSelect(): boolean {
    return useCurrentPageStore().configuration.userSelect;
  }

  get bypassFusionAuth(): boolean {
    return useCurrentPageStore().configuration.bypassFusionAuth;
  }

  get loginWithGoogleWorkspace(): boolean {
    return useCurrentPageStore().configuration.loginProvider === 'google_oauth2';
  }
}
</script>
