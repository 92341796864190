<template>
  <validation-observer ref="validations">
    <form-layout
    :disabled="!enableForm"
    form-id="patient-address-form"
  >
    <template v-slot:title>
      <!-- Mode indicator / subsection form title (H5) -->
      <legend>
        <h5 class="legend-title">
          {{ selection.isNew ? $t('new_patient_address_heading') : $t('selected_patient_address_heading') }}
        </h5>
      </legend>
    </template>
    <template v-slot:action>
      <!-- Action Toolbar -->
      <action-toolbar
        ref="addressActionToolbar"
        @archive="handleArchive"
        @restore="handleRestore"
        :permittedActions="editState.permittedActions"
        :isArchiving="isArchiving"
        :isRestoring="isRestoring"
        :loading="isLoadingActions"
      />
    </template>
    <template v-slot:contents>
      <!-- Data Entry Form (Add, View, and Edit modes) -->
      <loading-field v-if="isLoadingForm" />
      <div class="row" v-else>
        <div class="standard-form-group">
          <select-input
            ruleKey="patient_address.type"
            select-id="address-category"
            :name="$t('address_field.category')"
            :options="categoryOptions"
            v-model="editState.categoryType"
            @change="handleCategoryChanged"
          />
        </div>
      </div>

      <!-- start of Format details -->
      <loading-row v-if="editState.categoryType && isLoadingSubForm" />
      <template v-else-if="editState.categoryType">
        <component
          ref="addressFormatComponent"
          v-if="addressFormatComponentName"
          v-bind:is="addressFormatComponentName"
          :editState="editState"
          @countryChanged="handleCountryChanged"
        />
        <!-- Fallback -->
        <template v-else>
          <p class="mt-2">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ $t('address_error.missing_format_configuration', { formatCode: selectedCategoryFormatCode }) }}
          </p>
        </template>
      </template>
      <!-- end of Format details -->
    </template>
    <template v-slot:save>
      <save-toolbar
        :show="showSaveToolbar"
        :disabled="!enableSaveToolbar"
        ref="savePatientAddress"
        :label="$t('save_patient_address')"
        :cancelButton="true"
        @save="handleSave"
        @cancel="handleCancel"
      />
    </template>
  </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { GenericCodeValue } from '@/store/types';
import { IdLookup } from '@/store/validations/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Watch, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { UIAddress } from "@/UIModels/address";
import { AddressCategoryLookup } from '@/store/lookups/types';
import { SaveResult, APIResponseWithPermittedActions } from '@/types';
import USAOtherFormat from '@/components/recipients/contactInformation/patientAddress/USAOtherFormat.vue';
import NorthAmericaFormat from '@/components/recipients/contactInformation/patientAddress/NorthAmericaFormat.vue';
import CanadaOtherFormat from '@/components/recipients/contactInformation/patientAddress/CanadaOtherFormat.vue';
import CanadaOnlyFormat from '@/components/recipients/contactInformation/patientAddress/CanadaOnlyFormat.vue';
import USAOnlyFormat from '@/components/recipients/contactInformation/patientAddress/USAOnlyFormat.vue';
import USAStatesOtherFormat from '@/components/recipients/contactInformation/patientAddress/USAStatesOtherFormat.vue';
import LoadingRow from '@/components/shared/loading-skeletons/LoadingRow.vue';
import LoadingField from '@/components/shared/loading-skeletons/LoadingField.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { IdLookupProvider, RulesQuery } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { EP } from '@/api-endpoints';
import { i18nMessages } from '@/i18n';
import { parseFormErrors } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';

const addressFormatComponentMapping: { [key: string]: string } = {
  'usa_other': 'USAOtherFormat',
  'north_america': 'NorthAmericaFormat',
  'canada_other': 'CanadaOtherFormat',
  'canada_only': 'CanadaOnlyFormat',
  'usa_only': 'USAOnlyFormat',
  'usa_states_other': 'USAStatesOtherFormat',
};

@Component({
  components: {
    FormLayout,
    ActionToolbar,
    SaveToolbar,
    LoadingRow,
    LoadingField,
    SelectInput,
    USAOtherFormat,
    NorthAmericaFormat,
    CanadaOtherFormat,
    CanadaOnlyFormat,
    USAOnlyFormat,
    USAStatesOtherFormat,
  },
  ...i18nMessages([
    require('@/components/recipients/contactInformation/_locales/PatientAddress.json'),
  ]),
  emits: [
    'success',
  ],
})
export default class AddressForm extends mixins(DateUtilsMixin) implements IdLookupProvider {
  @State(state => state.lookups.address_categories) addressCategories!: AddressCategoryLookup[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Local component state is used to provide editable input values
  // NOTE: this local instance of the view model CAN be used for v-model in form template
  private editState = new UIAddress();

  // Here we are tracking prior Category and System Format to detect change to System Format
  private previousCategoryFormatCode: string|null = null;

  private isLoadingActions = false;
  private isLoadingForm = false;
  private isLoadingSubForm = false;

  private isArchiving = false;
  private isRestoring = false;
  private isSuccessfullySaving = false;

  // Can we enable the form?
  get enableForm(): boolean {
    if (this.newRecipient) return false;
    return this.canSave && this.editState.canEdit;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    if (this.newRecipient) return false;
    return this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.editState.canEdit;
  }

  // What address categories can be selected?
  get categoryOptions(): GenericCodeValue[] {
    return this.addressCategories;
  }

  // Which system format is associated with the currently selected "Category"?
  get selectedCategoryFormatCode(): string|null {
   if (!this.editState) return null;

    return this.addressCategoryFormat(this.editState.categoryType);
  }

  // Which form component do we need to use, based on system format associated with selected "Category"?
  get addressFormatComponentName(): string|null {
    const formatCode = this.selectedCategoryFormatCode;
    if (!formatCode) return null;

    const componentName = addressFormatComponentMapping[formatCode];
    if (!componentName) return null;

    return componentName;
  }

  // Reference to Patient Address toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.savePatientAddress;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  // Reference to Address Form's Action Toolbar
  get actionToolbar(): ActionToolbar|null {
    const actionToolbar = this.$refs.addressActionToolbar;
    if (!actionToolbar) return null;

    return actionToolbar as ActionToolbar;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // What is the first address category lookup entry set as the default?
  get defaultAddressCategory(): AddressCategoryLookup|null {
    return (this.addressCategories || []).find((addressCategory: AddressCategoryLookup) => { return addressCategory.default; }) || null;
  }

  // What is the lookup-based default value for "Category" field?
  get defaultCategoryType(): string|null {
    return this.defaultAddressCategory?.code || null;
  }

  // What is the lookup-based default value for "Country" field?
  // NOTE: this 'default' is applied only when the form initializes for New Address scenario, and only if "Category" has a default
  get defaultCategoryCountry(): string|null {
    return this.defaultAddressCategory?.default_country || null;
  }

  // What is the lookup-based default value for "Province" field?
  // NOTE: also applies to "State", "State or Territory", etc.
  get defaultCategoryProvince(): string|null {
    return this.defaultAddressCategory?.default_province || null;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};

    if (this.defaultCategoryType) params['type'] = this.defaultCategoryType;
    if (this.defaultCategoryCountry) params['country_code'] = this.defaultCategoryCountry;
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    const selectedCategoryType = this.editState.categoryType;
    const selectedCountryCode = this.editState.countryCode;

    if (selectedCategoryType) params['type'] = selectedCategoryType;
    if (selectedCountryCode) params['country_code'] = selectedCountryCode;
    return params;
  }

  // Extract 'default_country' string from address category lookup specified by the lookup entry code
  // NOTE: this 'default' is applied during data entry, if "Category" default is null and user assigns value for the first time
  private categoryDefaultCountry(categoryType: string|null): string|null {
    if (!this.addressCategories || !categoryType) return null;

    const categoryLookup = this.addressCategories.find((categoryLookup: AddressCategoryLookup) => {
      return categoryLookup.code === categoryType;
    });
    if (!categoryLookup) return null;

    return categoryLookup.default_country || null;
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;
    this.isLoadingSubForm = true;
    if (this.selection.id) {
      this.isLoadingActions = true;
    } else {
      this.editState.permittedActions = [];
    }

    // Make an edit state by loading the selected list item if it exists
    // NOTE: 'load' will initialize field-level validaiton rules, so here we pass contract 'query'
    const newEditState = new UIAddress();
    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;
    await newEditState.load({ recipientId: this.currentRecipient.clientId as string, id: this.selection.id as string, query });

    if (newEditState.isNew) {
      // When initializing a form edit state with no underlying selected model, set defaults!
      Object.assign(newEditState, {
        categoryType: this.defaultCategoryType,
        countryCode: this.defaultCategoryCountry,
        provinceCode: this.defaultCategoryProvince,
        stateCode: this.defaultCategoryProvince,
      });
    }
    this.editState = newEditState;

    // NOTE: we also need to keep track of 'previous' format to handle changes to type or format
    this.previousCategoryFormatCode = this.addressCategoryFormat(newEditState.categoryType);

    this.isLoadingActions = false;
    this.isLoadingForm = false;
    this.isLoadingSubForm = false;
  }

  // Load validation rules with specified query parameters
  // NOTE: this can involve either #new or #edit rules, depending on the situation
  private queryRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.selection.isNew ? this.queryNewAddressRules(query) : this.queryRecipientEditAddressRules(query);
  }

  // Load #edit rules with specified query parameters
  private queryRecipientEditAddressRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.patient_profile.addresses.edit_validations,
      payload: [[':recipient_id', this.recipientId], [':id', this.selection.id]],
      prefix: 'patient_address',
      query,
    });
  }

  // Load #new rules with specified query parameters
  private queryNewAddressRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.patient_profile.addresses.new_validations,
      payload: [[':recipient_id', this.recipientId]],
      prefix: 'patient_address',
      query,
    });
  }

  // Extract 'format_code' string from address category lookup specified by the lookup entry code
  private addressCategoryFormat(categoryType: string|null): string|null {
    if (!categoryType || !this.addressCategories) return null;

    const categoryLookup = this.addressCategories.find((categoryLookup: AddressCategoryLookup) => {
      return categoryLookup.code === categoryType;
    });
    if (!categoryLookup) return null;

    return categoryLookup.format_code;
  }

  // Process change of "Category" dropdown, in scenario when new category has different system format
  // NOTE: here is where we reset entire form, to prevent copying data that might be invalid in new format
  private clearFormatMismatchFields(_newFormat: string|null, _oldFormat: string|null): void {
    if (!this.editState) return;

    Object.assign(this.editState, {
      countryCode: null,
      provinceCode: null,
      stateCode: null,
      countryOther: null,
      streetAddress: null,
      city: null,
      postalCode: null,
      zipCode: null,
      urbanization: null,
    });
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams = { id: this.selection.id, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    this.isSuccessfullySaving = true;
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    this.$emit('success', success);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Process archive button click event
  private async handleArchive(): Promise<void> {
    this.isArchiving = true;
    this.resetSaveToolbar();
    const archiveParams = { selected: this.selection, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.archive(archiveParams);
      this.isArchiving = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.resetEditState();
    } catch (error: unknown) {
      this.isArchiving = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.handleErrors(error as SaveResult);
    }
  }

  // Process restore button click event
  private async handleRestore(): Promise<void> {
    this.isRestoring = true;
    this.resetSaveToolbar();
    const restorePrams = { selected: this.selection, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.restore(restorePrams);
      this.isRestoring = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.resetEditState();
    } catch (error: unknown) {
      this.isRestoring = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();

      this.handleErrors(error as SaveResult);
    }
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Process change to "Category" dropdown in form
  private async handleCategoryChanged(newCategoryType: string|null): Promise<void> {
    const oldFormat = this.previousCategoryFormatCode;
    const newFormat = this.selectedCategoryFormatCode;

    if (oldFormat != newFormat) {
      this.isLoadingSubForm = true;
      this.clearFormatMismatchFields(newFormat, oldFormat);
    }

    // If after changing Category the Country field has no value, we should set Default Country (AP-1038, AP-1105)
    if (!this.editState.countryCode) {
      const newDefaultCountry = this.categoryDefaultCountry(newCategoryType);
      this.editState.countryCode = newDefaultCountry;
    }

    this.previousCategoryFormatCode = newFormat;
    await this.queryRules(this.ruleQueryEditState);
    this.isLoadingSubForm = false;
  }

  // Process change to "Country" dropdown in form
  private handleCountryChanged(_newValue: string): void {
    this.queryRules(this.ruleQueryEditState);
  }

  // Process change to "selection" prop
  // NOTE: this will initialize New Address form, e.g. #new rules with query params based on lookup defaults
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(_newSelection: UIAddress, oldSelection?: UIAddress): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();

    // Reset save toolbar (unless it is showing success indicator)
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }

  // Initialize edit state e.g. defaults
  private mounted(): void {
    this.resetEditState();
  }

  // Validation mapping
  public idLookup(): IdLookup {
    const result: IdLookup = {
      'patient_address.type': 'address-category',
    };
    const addressFormatComponent = this.$refs.addressFormatComponent as unknown as IdLookupProvider;
    if (addressFormatComponent) {
      Object.assign(result, { ... addressFormatComponent.idLookup() });
    }
    return result;
  }

  // Dismiss save toolbar success or error indicator
  private resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }
}
</script>
