<template>
  <validation-provider
    ref="provider"
    :rules="formRules"
    :name="inputId"
    :label="name"
    :vid="validationId ? validationId : inputId"
    v-model="validatedValue"
    v-slot="{ errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <validation-asterisk
        v-if="!disabled" 
        :rules=formRules 
        :crossValues="crossValues" 
        :ruleKey="ruleKey"/>
      <slot name="link" />
    </label>
    <span class="mobile-spacing-wrapper">
      <template v-if="disabled">
        <input
          disabled 
          class="form-control"
          :title="getDisabledValue" 
          :value="getDisabledValue" />
      </template>
      <div>
        <AutoComplete 
          v-model="selectedValue"
          optionLabel="value" 
          :suggestions="optionsAvailable" 
          :placeholder="placeholder"
          @complete="search" 
          @change="change"
          :focusOnHover="true"
          :auto-option-focus="isSearching"
          :dropdown="showDropdown"
          @blur="onBlur"
          :class="{ 'is-invalid': errors[0] }"
          ref="autocompleteControl"
          v-bind="{ onItemSelect, onHide }"
          p-autocomplete-input-multiple
          :multiple="isMultiple"
          :fluid="isMultiple"
          :optionDisabled="disabledValue">
          <template #option="slotProps">
            <div :class="{
              'selected-option': slotProps.option.code === selectedValue, 
              'disabled-option': slotProps.option.code !== selectedValue && slotProps.option.disabled 
            }">
            {{ slotProps.option.value }}
            </div>
          </template>
        </AutoComplete>
        <div
            v-if="errors[0] && !disabled"
            class="invalid-feedback"
            :id="`${inputId}-error`"
          >
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ translateError(errors, label || name) }}
        </div>
      </div>
    </span>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import AutoComplete from 'primevue/autocomplete';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { TagObject } from '@/store/utilities/types';

@Component({
  components: {
    AutoComplete,
    ValidationAsterisk
  },
  emits: [
    'change',
    'update:modelValue',
  ],
})
export default class AutoCompleteInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('getTagsFromLookup', { namespace: 'utilities' }) getTagsFromLookup!: (lookup: any[]) => TagObject[];
  @Getter('isExpired', { namespace: 'utilities' }) private isExpired!: (expired_date?: string|undefined) => boolean;

  @Prop() modelValue!: any;

  @Prop({ required: true }) inputId!: string; // HTML ID
  @Prop({ default: null }) label!: string; // Label text (what the user clicks on)
  @Prop({ default: null }) name!: string|null;
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: null }) rules!: any; // Sets the validation rules
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end;getStyleDefaulting; // Key for value associated with group options
  @Prop({ required: true }) options!: any[]; // Enumerable data for building the options

  @Prop({ default: "Select..." }) placeholder!: string;
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  @Prop({ default: false }) hide_restricted_permissions!: boolean; // Optional, if true, options with restricted_permissions are filtered out
  @Prop({ default: false }) filterByLookupRule!: boolean;
  @Prop({ default: true }) hideExpired!: boolean; // By default filter out expired options
  @Prop({ default: false }) showDropdown!: boolean; // Allows autocomplete mulitiselect
  
  @Prop({ default: false }) isMultiple!: boolean; // Allows autocomplete mulitiselect

  private validatedValue: string|null = null;
  private selectedValue: any = null;

  private optionsAvailable: any = null;
  private isSearching: any = null;
  private shouldReopen: any = false;

  private instance: any = null

  private buildSelectedValue(value: string): any {
    const found = this.getOptions.find((option: any) => { return option.code === value; });
    if (!found) return null;
    return {
      code: found.code,
      value: found.value
    };
  }

  public mounted(): void {
    this.optionsAvailable = this.getOptions;

    // generate object from string value (selectedValue should be an object)
    const found = this.optionsAvailable.find((item: any) => {
      return item.code === this.modelValue;
    });

    if (found) {
      this.selectedValue = found;
      this.validatedValue = found.code;
    }
  }

  // What lookup rule codes are defined for this field?
  get inLookupRuleCodes(): string[] {
    const formRules = this.formRules || '';
    const inLookupRule = formRules.match(/(?:.*)in_lookup\:((?:(?:.*),?)*)(?:.*)/);
    return inLookupRule ? (inLookupRule[1] || '').split(',') : [];
  }

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  get getDisabledValue(): string|undefined {
    if (!this.selectedValue) return undefined;
    return this.selectedValue.value;
  }

  get getOptions(): any[] {
    // Filter out expired options if needed
    const options = this.hideExpired ? this.filterOutExpiredOptions(this.options) : this.options;
    // If using the 'filter by lookup rule' option, remove options not included in 'in_lookup' rule definition
    const filtedByRules = this.filterByLookupRule && this.inLookupRuleCodes.length > 0 ? options.filter((option: any) => { return this.inLookupRuleCodes.includes(option.code); }) : options;
    // If hide_restricted_permissions enabled, filter out options with restricted_permissions = true
    const filterByRestrictedPermissions = this.hide_restricted_permissions ? filtedByRules.filter((option: any) => { return !option.restricted_permissions; }) : filtedByRules;

    // return fully filtered options
    return filterByRestrictedPermissions;
  }

  get getOptionsMinusDisabled(): any[] {
    // Hide all disabled options (this is especially useful for serology as markers cannot reuse the same code)
    return this.getOptions.filter((option: any) => { return !option.disabled; });
  }

  // filter out expired options
  filterOutExpiredOptions(options: any[]): any[] {
    if (!options) return [];

    return options.filter((option: any) => {
      // return non-expired items
      if (!this.isExpired(option.expired_date)) return option;
    });
  }

  private search(event: any): void {
    this.isSearching = true;
    // filter out options that don't match and re-set the variable
    this.optionsAvailable = this.getOptionsMinusDisabled.filter((item: any) => {
      return item.value.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  private change(event: any): void {
    const eventValue = event.value;
    this.$emit('update:modelValue', eventValue);
    this.$emit('change', eventValue);

    this.optionsAvailable = this.getOptions;
    this.validationProvider.validate();
  }

  private onBlur(event: any): void {
    this.validationProvider.validate();
  }  

  @Watch('modelValue')
  updateValidatedValue() {
    this.selectedValue = this.modelValue;
    this.validatedValue = this.selectedValue ? this.selectedValue.code : null;
  }

  // Ref to vee-validate Field a.k.a. ValidationProvider
  get validationProvider() {
    return this.$refs.provider as any;
  }

  // multiple changes

  private openDropdown() {
    const autocomplete = this.$refs.autocompleteControl as any;
    if (autocomplete) { 
      autocomplete.show();
    }
  }

  private onItemSelect() {
    this.shouldReopen = true;
  }

  private onHide() {
    if (this.shouldReopen) {
      setTimeout(() => {
        this.openDropdown();
      }, 100);
      this.shouldReopen = false;
    }
  }

  private disabledValue(option: any): any {
   if (this.isMultiple && this.selectedValue && this.selectedValue.length) {

    const found = this.selectedValue.find((item: any) => { 
      return item.code == option.code;
    });
    return found ? true : false;
  }
}

}


</script>

<style>

.p-autocomplete-option.p-autocomplete-option-selected.p-disabled {
    display: none !important;
  }
  .p-autocomplete-input-multiple input::placeholder {	
    color: #78909c !important;
    font-size: 0.85rem !important;
  }

  .p-autocomplete-input-multiple input::placeholder {	
    color: #78909c !important;
    font-size: 0.85rem !important;
  }

  .p-autocomplete-input-multiple .p-autocomplete-chip-item {
    min-width: max-content;
  }
</style>
