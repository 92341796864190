<template>
  <div>
  <div class="card summary-card">
    <span class="skeleton-box skeleton-title w-100" />  
    <div class="row skeleton-padding-text">
      <div class="standard-form-group" v-for="(n,index) in 4" :key="index">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
    </div>
    <div class="row skeleton-padding-text">
      <div class="standard-full-width-group">
          <span class="skeleton-box w-50" />
          <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
    </div>
    <div class="row skeleton-padding-text">
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>   
      <hr/>      
    </div>
    <div class="row skeleton-padding-text">
      <div class="col-12">
        <span class="skeleton-box w-100 col-xs-12" style="height: 50px;" />
      </div>
      <br/>
      <div class="standard-form-group-large">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
      <div class="standard-form-group">
        <span class="skeleton-box w-50" />
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>         
    </div>
  </div>
  <hr>
  <span class="skeleton-box skeleton-button-left" /><br/>

</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoadingSection from '@/components/shared/loading-skeletons/LoadingSection.vue';

@Component({
  components: {
    LoadingSection
  }
})
export default class LoadingProfilePage extends Vue {}
</script>
