<template>
  <div v-if="isProtoHeartSpecificDetailsEnabled">
  <card-section
    section-id="proto_heart_specific_details"
  >
    <template v-slot:header>
      Heart Specific Details
    </template>
    <template v-slot:body>
      <proto-heart-hemodynamic-information />
    </template>
  </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import ProtoHeartHemodynamicInformation from '@/prototypes/organs/heart/ProtoHeartHemodynamicInformation.vue';



@Component({
  components: {
    CardSection,
    ProtoHeartHemodynamicInformation
  }
})
export default class ProtoHeartSpecificDetails extends Vue {


  get isProtoHeartSpecificDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.HeartSpecificDetails);
  }
}
</script>
