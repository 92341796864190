import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proto_egfr_section = _resolveComponent("proto-egfr-section")!
  const _component_proto_dialysis_events = _resolveComponent("proto-dialysis-events")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoEgfrAndDiaylsisEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_egfr_and_dialysis" }, {
          header: _withCtx(() => [
            _createTextVNode(" eGFR and Dialysis ")
          ]),
          body: _withCtx(() => [
            _createVNode(_component_proto_egfr_section),
            _createVNode(_component_proto_dialysis_events)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}