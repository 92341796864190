<template>
  <Paginator
    v-if="internalPageSize"
    :class="className"
    :first="pageOffset"
    :rows="internalPageSize"
    :totalRecords="numberOfRecords" 
    :rowsPerPageOptions="perPageDropdown"
    @page="onPageNumber($event)"
    :template="paginationTemplate"
    :currentPageReportTemplate="currentPageReportTemplate"
    >
    <template v-slot:start>
      <span>{{ $t('results_per_page') }}:</span>
      <SelectInput
        :selectId="id"
        :name="$t('rows_per_page')"
        v-model="internalPageSize"
        :hideLabel="true"
        :options="perPageDropdown"
        @change="onPageSize($event)"
        :inline="true"
        :nullable="false"
        :numeric="true"
      />
    </template>
    <template #previcon>
      <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
    </template>
    <template #nexticon>
      <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
    </template>
  </Paginator>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import Paginator from 'primevue/paginator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { i18nMessages } from '@/i18n';

const PAGE_SIZES = [5, 10, 25];

@Component({
  components: {
    Paginator,
    SelectInput
  },
  ...i18nMessages([
    require('@/components/shared/_locales/TableList.json'),
  ]),
  emits: [
    'onPageNumber',
    'onPageSize',
  ],
})
export default class TablePaginator extends Vue {
  @Prop({ required: true }) id?: string;
  @Prop({ default: null }) pageOffset?: number|undefined;
  @Prop({ default: null }) pageSize?: number|undefined;
  @Prop({ default: null }) numberOfRecords?: number|undefined;
  @Prop({ default: PAGE_SIZES }) perPageDropdown?: number[];
  @Prop({ default: null }) jumpToPage?: boolean;

  internalPageSize: number|undefined = PAGE_SIZES[0];

  onPageNumber(event: any): void {
    this.$emit('onPageNumber', event);
  }

  onPageSize(event: any): void {
    this.$emit('onPageSize', event);
  }

  mounted(): void {
    this.internalPageSize = this.pageSize;
  }

  get className(): any {
    return { 
      'p-paginator-spacer': !!this.numberOfRecords && this.numberOfRecords > 0
    };
  }

  @Watch('pageSize', { immediate: true, deep: true })
  private updatePageSize(): void {
    this.internalPageSize = this.pageSize;
  }
  
  get paginationTemplate(): string {
    return this.jumpToPage ? 'JumpToPageInput CurrentPageReport PrevPageLink NextPageLink' : 'CurrentPageReport PrevPageLink NextPageLink';
  }

  get currentPageReportTemplate(): string {
    return this.jumpToPage ? 'of {totalPages}' : '{first} - {last} of {totalRecords}';
  }
}
</script>
