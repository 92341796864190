<template>
  <div>
    <loading-recipient-page v-if="!recipient" />
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue } from 'vue-facing-decorator';
import LoadingRecipientPage from '@/components/shared/loading-skeletons/LoadingRecipientPage.vue';


@Component({
  components: {
    LoadingRecipientPage
  },
})
export default class BaseRecipient extends Vue {
  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;
}
</script>
