import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "mobile-spacing-wrapper" }
const _hoisted_3 = ["title", "value"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "link")
      ], 10, _hoisted_1),
      _createElementVNode("span", _hoisted_2, [
        (_ctx.disabled)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              disabled: "",
              class: "form-control",
              title: _ctx.getDisabledValue,
              value: _ctx.getDisabledValue
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_AutoComplete, _mergeProps({
            modelValue: _ctx.selectedValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
            optionLabel: "value",
            suggestions: _ctx.optionsAvailable,
            placeholder: _ctx.placeholder,
            onComplete: _ctx.search,
            onChange: _ctx.change,
            focusOnHover: true,
            "auto-option-focus": _ctx.isSearching,
            dropdown: _ctx.showDropdown,
            onBlur: _ctx.onBlur,
            class: { 'is-invalid': errors[0] },
            ref: "autocompleteControl"
          }, { onItemSelect: _ctx.onItemSelect, onHide: _ctx.onHide }, {
            "p-autocomplete-input-multiple": "",
            multiple: _ctx.isMultiple,
            fluid: _ctx.isMultiple,
            optionDisabled: _ctx.disabledValue
          }), {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", {
                class: _normalizeClass({
              'selected-option': slotProps.option.code === _ctx.selectedValue, 
              'disabled-option': slotProps.option.code !== _ctx.selectedValue && slotProps.option.disabled 
            })
              }, _toDisplayString(slotProps.option.value), 3)
            ]),
            _: 2
          }, 1040, ["modelValue", "suggestions", "placeholder", "onComplete", "onChange", "auto-option-focus", "dropdown", "onBlur", "class", "multiple", "fluid", "optionDisabled"]),
          (errors[0] && !_ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "invalid-feedback",
                id: `${_ctx.inputId}-error`
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'exclamation-circle'],
                  "fixed-width": ""
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}