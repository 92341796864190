<template>
  <form>
    <sub-section
      sub-section-id="proto-diaylsis-events-section"
      :title="title"
    >
    <template v-slot:contents>
      
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="true"
        createText="Create Diaylsis Event"
        @table-create-row="clearForm"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="contactFilter"
                :showFilter="false"
                :showArchived="false"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="dialysiseventsTable"
        tabbableColumn="calculated_date"
        table-id="diaylsis-event-table"
        :table-config="DiaylsisEventsTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
     
        <form-layout
        form-id="sodiemMeld-form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="isNew" class="legend-title">
                New Dialysis Event
              </h5>
              <h5 v-else class="legend-title has-guiding-text">
                Selected Dialysis Event
              </h5>
            </legend>
          </template>
          <template v-slot:contents>
      <!-- Form layout -->
      <form-layout
      form-id="dialysis-score-form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                select-id="reason-for-dialysis?"
                name='Reason for Dialysis?'
                rules="required" 
                :options="dialysisReasonOptions"
                v-model="editState.dialysis_reason"
                >               
              </select-input>
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="start-date"
                rules="required" 
                name='Start Date'
                v-model="editState.start_date"   
              />
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="end-date"
                name='End Date'
                v-model="editState.end_date"   
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                select-id="dialysis-type"
                name='Dialysis Type'
                :options="dialysisTypeOptions"
                v-model="editState.dialysis_type"
                >               
              </select-input>
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="access-mode"
                name='Access Mode'
                :options="accessModeOptions"
                v-model="editState.access_mode"
                >               
              </select-input>
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="access-laterality"
                name='Access Laterality'
                :options="accessLateralityOptions"
                v-model="editState.access_laterality"
                >               
              </select-input>
            </div>
            
          </div>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                select-id="location"
                name='Location'
                :options="locationOptions"
                v-model="editState.location"
                >               
              </select-input>
            </div>
            <div class="standard-form-group">
              <text-input 
                inputId="facility-name" 
                name="Facility Name"
                :disabled="!editState.location || editState.location == '0'"
                v-model="editState.facility_name"/>
            </div>
            <div class="standard-form-group">
              <text-input 
                inputId="facility-phone-number" 
                name="Facility Phone Number"
                :mask="getMask"
                :disabled="!editState.location || editState.location == '0'"
                v-model="editState.facility_phone_number"/>
            </div>
          </div>

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveDialysisEvent"
            label="Save Dialysis Event"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>


      </form-layout>
      
    </template>
        </form-layout>    
    </template>
    </sub-section>
</form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';

import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import {parsePhoneUi} from '@/utils';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface DialysisEventsForm {
  id: string,
  dialysis_reason: string,
  start_date: Date|null,
  end_date: Date|null,
  dialysis_type: string,
  access_mode: string,
  access_laterality: string,
  location: string,
  facility_name: string,
  facility_phone_number: string|null,
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextAreaInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    NumberInput, 
    SelectInput,
    TextInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoDialysisEvents extends mixins(DateUtilsMixin) {

  private title: any = "Dialysis Events";
  private isNew: any = true;
  private isArchiving = false;
  private isRestoring = false;
  private isLoadingActions = false;

  private editState: DialysisEventsForm = {
    id: '',
    dialysis_reason: '',
    start_date: null,
    end_date: null,
    dialysis_type: '',
    access_mode: '',
    access_laterality: '',
    location: '',
    facility_name: '',
    facility_phone_number: '',
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private dialysisevents: any = [];

  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private isLoading = true;
  private isSubSectionLoading = true;
  private selectedItem = '';
  private emptyContactMethodForm = '';
  private displayFilter = true;
  private setFilters: any = {};
  private modalMode = ""
  private rows = [];

  // used by parent component
  @Watch('editState.location')
  onLocationSwitch(): void {
    this.editState.facility_name = '';
    this.editState.facility_phone_number = '';
}

  get getMask(): string {
    return "+1 (###) ###-####";
  }

  private dialysisReasonOptions = [
    {
      code: 0,
      value: 'Acute Treatment'
    }, {
      code: 1,
      value: 'Chronic Treatment'
    }
  ];

  private dialysisTypeOptions = [
    {
      code: 0,
      value: 'Continuous Ambulatory Peritoneal Dialysis (CAPD)'
    }, 
    {
      code: 1,
      value: 'Automated Peritoneal Dialysis (APD)'
    },
    {
      code: 2,
      value: 'Continuous Renal Replacement Therapy (CRRT)'
    }, 
    {
      code: 3,
      value: 'Conventional Hemodialysis (HD)'
    },
    {
      code: 4,
      value: 'Other/Unknown'
    }
  ];

  private accessModeOptions = [
    {
      code: 0,
      value: 'Arteriovenous Fistula'
    }, 
    {
      code: 1,
      value: 'Arteriovenous Graft'
    },
    {
      code: 2,
      value: 'Central Venous Catheter'
    }, 
    {
      code: 3,
      value: 'Abdominal Port for Peritoneal Dialysis'
    },
    {
      code: 4,
      value: 'Other'
    }
  ];

  
  private accessLateralityOptions = [
    {
      code: 0,
      value: 'Left'
    }, {
      code: 1,
      value: 'Right'
    }
  ];

  private locationOptions = [
    {
      code: 0,
      value: 'Home'
    }, {
      code: 1,
      value: 'Hospital'
    },
    {
      code: 1,
      value: 'Clinic'
    }
  ];
  
  

  get DiaylsisEventsTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Reason for Dialysis', field: 'dialysisReasonValue', width: '200px'},
      { label: 'Start Date', field: 'startDateDisplayValue',  width: '125px' },
      { label: 'End Date', field: 'endDateDisplayValue',  width: '125px'},
      { label: 'Dialysis Type', field: 'dialysisTypeValue',  width: '350px'},
      { label: 'Location', field: 'locationValue',  width: '85px'},
    ],
    empty: 'Use the form below to add a new dialysis event.',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'start_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}
      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveDialysisEvent as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.DiaylsisEvents, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: DialysisEventsForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.dialysisevents = response;
}

get tableRows() {
  const dialysisevents = this.dialysisevents || [];

  if (dialysisevents.length > 0) {

  return dialysisevents.map((event: any) => {
    return {
      id: event.id,
      dialysis_reason: event.dialysis_reason || '-',
      dialysisReasonValue: this.getCategoryValue( event.dialysis_reason, this.dialysisReasonOptions) || '-',
      start_date: event.start_date  || '-',
      startDateDisplayValue: this.parseDisplayDateUi(event.start_date) || '-',
      end_date: event.end_date  || '-',
      endDateDisplayValue: this.parseDisplayDateUi(event.end_date) || '-',
      dialysis_type: event.dialysis_type  || '-',
      dialysisTypeValue: this.getCategoryValue( event.dialysis_type, this.dialysisTypeOptions) || '-',
      access_mode: event.access_mode  || '-',
      access_laterality: event.access_laterality  || '-',
      location: event.location || '-',
      locationValue: this.getCategoryValue( event.location, this.locationOptions) || '-',
      facility_name: event.facility_name,
      facility_phone_number: event.facility_phone_number,
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let dialysisevents = this.dialysisevents || [];

  const payload: DialysisEventsForm = {
    id: Math.random().toString(16).slice(2),
    dialysis_reason: this.editState.dialysis_reason,
    start_date: this.editState.start_date,
    end_date: this.editState.end_date,
    dialysis_type: this.editState.dialysis_type,
    access_mode: this.editState.access_mode,
    access_laterality: this.editState.access_laterality,
    location: this.editState.location,
    facility_name: this.editState.facility_name,
    facility_phone_number: this.editState.facility_phone_number
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = dialysisevents.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      dialysisevents[foundIndex] = payload;
    }
  } else {  
    dialysisevents.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, dialysisevents);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 

public getCategoryValue(item: any, data: any) {
  if (!item || !data) return null;

  const entry = data[item as any];
  if (!entry) return null;

  return entry.value;
}



private editSelected(row: any) {
  if (row) {  
    this.editState.id = row.row.id;
    this.editState.dialysis_reason = row.row.dialysis_reason;
    this.editState.start_date = row.row.start_date;
    this.editState.end_date = row.row.end_date;
    this.editState.dialysis_type = row.row.dialysis_type;
    this.editState.access_mode = row.row.access_mode;
    this.editState.access_laterality = row.row.access_laterality;
    this.editState.location = row.row.location;
    this.isNew = false;
    setTimeout(() => {
      this.editState.facility_name = row.row.facility_name;
      this.editState.facility_phone_number = row.row.facility_phone_number;
      }, 200);
    }
  }

  public clearForm(): any {
    this.isNew = false;
    this.editState.dialysis_reason = "";
    this.editState.start_date = null;
    this.editState.end_date = null;
    this.editState.dialysis_type = "";
    this.editState.access_mode = "";
    this.editState.access_laterality = "";
    this.editState.location = "";
    this.editState.facility_name = "";
    this.editState.facility_phone_number = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get dialysisEventsTable(): any {
    return this.$refs.dialysisEventsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.dialysisEventsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.dialysisEventsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveDialysisEvent as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>

<style>
</style>
