import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_ctx.isProtoKidneyInformationEnabled)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createVNode(_component_sub_section, {
          "sub-section-id": "proto-Kidney-information-section",
          title: _ctx.title
        }, {
          contents: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "sodiemMeld-form" }, {
              contents: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_date_input, {
                      inputId: "serum-creatinine-date",
                      name: "Serum Creatinine Date",
                      modelValue: _ctx.editState.serum_creatinine_date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.serum_creatinine_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_number_input, {
                      inputId: "kidney-serum_creatinine",
                      name: "Serum Creatinine",
                      append: true,
                      appendText: "µmol/L",
                      step: "0.1",
                      modelValue: _ctx.editState.serum_creatinine,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.serum_creatinine) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_date_input, {
                      inputId: "a1c-date",
                      name: "A1C Date",
                      modelValue: _ctx.editState.a1c_date,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.a1c_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_number_input, {
                      inputId: "a1c-value",
                      name: "A1C",
                      append: true,
                      appendText: "%",
                      step: "0.1",
                      modelValue: _ctx.editState.a1c_value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.a1c_value) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_date_input, {
                      inputId: "serum-albumin-date",
                      name: "Serum Albumin Date",
                      modelValue: _ctx.editState.serum_albumin_date,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.serum_albumin_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_number_input, {
                      inputId: "kidney-serum_albumin",
                      name: "Serum Albumin",
                      append: true,
                      appendText: "g/dL",
                      step: "0.1",
                      modelValue: _ctx.editState.serum_albumin,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.serum_albumin) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_date_input, {
                      inputId: "serum-bun-date",
                      name: "Serum BUN Date",
                      modelValue: _ctx.editState.serum_bun_date,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.serum_bun_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_number_input, {
                      inputId: "serum-bun",
                      name: "Serum BUN",
                      append: true,
                      appendText: "mg/dL",
                      step: "0.1",
                      modelValue: _ctx.editState.serum_bun,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.serum_bun) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_select_input, {
                      "select-id": "urine-output",
                      name: "Urine Output",
                      options: _ctx.urineOutputOptions,
                      modelValue: _ctx.editState.urine_output,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.urine_output) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_select_input, {
                      "select-id": "urine-output-volume",
                      name: "Output Volume",
                      options: _ctx.urineOutputVolumneOptions,
                      disabled: !_ctx.editState.urine_output,
                      modelValue: _ctx.editState.urine_output_volume,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.urine_output_volume) = $event))
                    }, null, 8, ["options", "disabled", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_text_area_input, {
                      "input-id": "kidney-info-comments",
                      name: "Comments",
                      rows: "4",
                      modelValue: _ctx.editState.comments,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editState.comments) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveKidneyInformation",
                  label: "Save Kidney Information",
                  cancelButton: true,
                  onSave: _cache[11] || (_cache[11] = ($event: any) => (_ctx.performSave())),
                  onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearForm()))
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ]))
    : _createCommentVNode("", true)
}