import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_ctx.internalPageSize)
    ? (_openBlock(), _createBlock(_component_Paginator, {
        key: 0,
        class: _normalizeClass(_ctx.className),
        first: _ctx.pageOffset,
        rows: _ctx.internalPageSize,
        totalRecords: _ctx.numberOfRecords,
        rowsPerPageOptions: _ctx.perPageDropdown,
        onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPageNumber($event))),
        template: _ctx.paginationTemplate,
        currentPageReportTemplate: _ctx.currentPageReportTemplate
      }, {
        start: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('results_per_page')) + ":", 1),
          _createVNode(_component_SelectInput, {
            selectId: _ctx.id,
            name: _ctx.$t('rows_per_page'),
            modelValue: _ctx.internalPageSize,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalPageSize) = $event)),
            hideLabel: true,
            options: _ctx.perPageDropdown,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPageSize($event))),
            inline: true,
            nullable: false,
            numeric: true
          }, null, 8, ["selectId", "name", "modelValue", "options"])
        ]),
        previcon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'caret-left'],
            "fixed-width": ""
          })
        ]),
        nexticon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'caret-right'],
            "fixed-width": ""
          })
        ]),
        _: 1
      }, 8, ["class", "first", "rows", "totalRecords", "rowsPerPageOptions", "template", "currentPageReportTemplate"]))
    : _createCommentVNode("", true)
}