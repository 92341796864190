<template>
  <span>
    <router-link :to="{ name: 'proto-recipients' }" v-if="isProtoRecepientListingsEnabled">
      Patients
    </router-link>
    <router-link :to="{ name: 'list-recipients' }" v-else>
      {{ $t('recipients') }}
    </router-link>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('@/views/_locales/common.json'),
  ])
})
export default class ListRecipientsLink extends Vue {
  get isProtoRecepientListingsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoRecipientsListings);
  }
}
</script>
