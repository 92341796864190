import { APIPostTransplantFollowUpInterface, PostTransplantFollowUpValues } from '@/APIModels/journey/types';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { SaveResult, APISavePostTransplantFollowUpResponse, 
  APIFieldLevelValidationErrorData, APIRules, APINewResonse, APIShowResponse, APIShowFollowUpData } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { currentDate } from '@/utilities/date-utils';

export class UIPostTransplantFollowUp {
  public apiSource?: APIPostTransplantFollowUpInterface;
  public id: string|null = null;
  public loaded = false;

  public entry_date?: string|null = null;
  public follow_up_type?: string|null = null;
  public years_since_transplant?: string|null = null;
  public graft_status_code?: string|null = null;
  public follow_up_date?: string|null = null;
  public graft_rejection_date?: string|null = null;
  public graft_failure_date?: string|null = null;
  public graft_failure_cause_code?: string|null = null;
  public donor_specific_antibody?: string|null = null;
  public recipient_status_code?: string|null = null;
  public lost_to_follow_up_date?: string|null = null;
  public lost_to_follow_up_reason?: string|null = null;

  public permitted_actions: string[] = [];

  public constructor(apiPostTransplantFollowUp?: APIPostTransplantFollowUpInterface) {
    if (apiPostTransplantFollowUp) this.updateFromAPIResponse(apiPostTransplantFollowUp);

    if (!apiPostTransplantFollowUp) {
      // set entry_date to today's date
      this.entry_date = currentDate();
    }
  }

  // Load resource data and permitted actions
  public async load(opts: { uiJourney: UIJourney, id: string }): Promise<void> {
    if (!opts.uiJourney) return;
    if (opts.id) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  // Load list for specified recipient
  public static async loadFor(uiJourney: UIJourney): Promise<UIPostTransplantFollowUp[]> {
    const clientId = uiJourney.clientId || '';
    const journeyId = uiJourney.journeyId || '';

    try {
      let result: UIPostTransplantFollowUp[] = [];
      const url = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.index, [[':recipientId', (clientId as string)], [':journeyId', (journeyId as string)]]);
      await axios.get(url).then((response: any) => {
        const apiPostTransplantFollowUps: APIPostTransplantFollowUpInterface[] = response?.data?.follow_ups || [];
        result = apiPostTransplantFollowUps.map((item: APIPostTransplantFollowUpInterface): UIPostTransplantFollowUp => {
          return new UIPostTransplantFollowUp(item);
        });
      });
      return result;
    } catch (errorResponse: unknown) {
      console.warn((new UIError('post_transplant_follow_ups', errorResponse)).errorResult);
      throw new UIError('post_transplant_follow_ups');
    }
  }

  private async loadNew(opts: { uiJourney: UIJourney, id: string }): Promise<void> {
    const url = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.new, [[':recipientId', (opts.uiJourney.clientId as string)], [':journeyId', (opts.uiJourney.journeyId as string)], [':id', (opts.id)]]);
    try {
      const response: APINewResonse = await axios.get(url);
      this.permitted_actions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private async loadShow(opts: { uiJourney: UIJourney, id: string }): Promise<void> {
    const url = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.show, [[':recipientId', (opts.uiJourney.clientId as string)], [':journeyId', (opts.uiJourney.journeyId as string)], [':id', (opts.id)]]);
    try {
      const response: APIShowResponse<APIShowFollowUpData> = await axios.get(url);
      const apiFollowUp: APIPostTransplantFollowUpInterface = response.data.follow_up;
      this.permitted_actions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.updateFromAPIResponse(apiFollowUp);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'post_transplant_follow_ups');
    vuexStore.commit('validations/set', { rules: { ['post_transplant_follow_ups']: rules } });
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiPostTransplantFollowUp: APIPostTransplantFollowUpInterface): void {
    this.apiSource = apiPostTransplantFollowUp;

    this.id = apiPostTransplantFollowUp._id?.$oid || null;
    this.entry_date = apiPostTransplantFollowUp.entry_date || null;
    this.follow_up_type = apiPostTransplantFollowUp.follow_up_type || null;
    this.years_since_transplant = apiPostTransplantFollowUp.years_since_transplant || null;
    this.graft_status_code = apiPostTransplantFollowUp.graft_status_code || null;
    this.follow_up_date = apiPostTransplantFollowUp.follow_up_date || null;
    this.graft_rejection_date = apiPostTransplantFollowUp.graft_rejection_date || null;
    this.graft_failure_date = apiPostTransplantFollowUp.graft_failure_date || null;
    this.graft_failure_cause_code = apiPostTransplantFollowUp.graft_failure_cause_code || null;
    this.donor_specific_antibody = apiPostTransplantFollowUp.donor_specific_antibody || null;
    this.recipient_status_code = apiPostTransplantFollowUp.recipient_status_code || null;
    this.lost_to_follow_up_date = apiPostTransplantFollowUp.lost_to_follow_up_date || null;
    this.lost_to_follow_up_reason = apiPostTransplantFollowUp.lost_to_follow_up_reason || null;

    this.loaded = true;
  }

  // Build a copy of specific details for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel() {
    const apiSource = this.apiSource as APIPostTransplantFollowUpInterface;
    if (!apiSource) return new UIPostTransplantFollowUp();

    return new UIPostTransplantFollowUp(apiSource);
  }

  public get isNew(): boolean {
    return this.apiSource === null;
  }

  // Save edit state to the backend
  public save(opts: { selected: UIPostTransplantFollowUp, recipient: UIRecipient, journey: UIJourney }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selected: UIPostTransplantFollowUp|null = opts.selected || null;      
      const recipientId = opts.recipient.clientId;
      const journeyId = opts.journey.journeyId;
      if (!recipientId) reject((new UIError('post_transplant_follow_ups')));
      if (!journeyId) reject((new UIError('post_transplant_follow_ups')));

      let method: any;
      let ep: string;

      if (selected.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.update, [[':recipientId', recipientId as string], [':journeyId', journeyId as string], [':id', selected.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.post_transplant.follow_ups.create, [[':recipientId', recipientId as string], [':journeyId', journeyId as string]]);
      }
      const payload = this.extractPatch();

      method(ep, payload).then((response: APISavePostTransplantFollowUpResponse) => {
        if (response.data.errors) {
          reject((new UIError('post_transplant_follow_ups', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          UIPostTransplantFollowUp.loadFor(opts.journey).then((followUps: UIPostTransplantFollowUp[]) => {
            opts.journey.postTransplantFollowUps = followUps;
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('post_transplant_follow_ups', errorResponse)).errorResult);
      });
    });
  }

  // Generate request payload parameters to provide to API for a Referral Attributes patch
  private extractPatch(): APIPostTransplantFollowUpInterface {

    // do not include entry_date (calculated field)

    const result: APIPostTransplantFollowUpInterface = {
      follow_up_type: this.follow_up_type || null,
      graft_status_code: this.graft_status_code || null,
      recipient_status_code: this.recipient_status_code || null,
    };

    result.years_since_transplant = (this.follow_up_type === PostTransplantFollowUpValues.Annual) ? this.years_since_transplant : null;

    result.follow_up_date = (this.recipient_status_code === PostTransplantFollowUpValues.Living) ? this.follow_up_date : null;

    result.lost_to_follow_up_date = (this.recipient_status_code === PostTransplantFollowUpValues.LossOfContact) ? this.lost_to_follow_up_date : null;
    result.lost_to_follow_up_reason = (this.recipient_status_code === PostTransplantFollowUpValues.LossOfContact) ? this.lost_to_follow_up_reason : null;

    result.graft_rejection_date = (this.graft_status_code === PostTransplantFollowUpValues.Rejection) ? this.graft_rejection_date : null;
    result.donor_specific_antibody = (this.graft_status_code === PostTransplantFollowUpValues.Rejection) ? this.donor_specific_antibody : null;

    result.graft_failure_date = (this.graft_status_code === PostTransplantFollowUpValues.Failure) ? this.graft_failure_date : null;
    result.graft_failure_cause_code = (this.graft_status_code === PostTransplantFollowUpValues.Failure) ? this.graft_failure_cause_code : null;  

    return result;
  }

  public setPermittedActions(permitted_actions: string[]) {
    this.permitted_actions = permitted_actions;
  }
}
