<template>
  <div v-if="isProtoTransportationAndMobilityEnabled">
    <card-section
      section-id="proto_transportation_and_mobility"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="proto-transportation-and-mobility-form">
          <template v-slot:contents>
            <sub-section 
              :title="title" 
              sub-section-id="proto-transportation-and-mobility-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                  <div class="col-sm-12 transportation-section">
                    <fieldset>
                      <div class="row">
                        <div class="standard-form-group form-group-checkbox">
                          <label>Patient has own transportation?</label>
                          <boolean-radio-input
                            ruleKey="hasTransportation"
                            input-id='hasTransportation'
      
                            :acceptId="true"
                            class="mt-4"
                            :declineId="false"
                            :acceptLabel="$t('yes')"
                            :declineLabel="$t('no')"
                            v-model="editState.hasTransportation"
                          /> 
                        </div>                    
                        <div class="standard-form-group form-group-checkbox">
                          <label>Flags</label>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input 
                                name="avoid-cities" 
                                hideLabel="true" 
                                labelName="Avoid cities" 
                                inputId="avoid-cities"
                                v-model="editState.avoidCities" 
                                label='Avoid cities' />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input 
                                name="avoid-highways" 
                                hideLabel="true" 
                                labelName="Avoid highways" 
                                inputId="highways"
                                v-model="editState.avoidHighways" 
                                label='Avoid highways' />
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <checkbox-input 
                                name="gas-money-sensitivity" 
                                hideLabel="true" 
                                labelName="Gas money sensitivity"
                                inputId="gas-money-sensitivity" 
                                v-model="editState.gasMoneySensitivity" 
                                label='Gas money sensitivity' />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input 
                                name="mobility-challenges" 
                                hideLabel="true" 
                                labelName="Mobility challenges"
                                inputId="mobility-challenges" 
                                v-model="editState.mobilityChallenges" 
                                label='Mobility challenges' />
                            </div>
                          </div>
                        </div>
                        <div class="standard-form-group-large">
                          <div class="row">
                            <div class="col-6">
                              <number-input 
                                inputId="distance-miles" 
                                name="Max Distance (miles)" 
                                v-model="editState.maxMiles" />

                            </div>
                            <div class="col-6">
                              <number-input 
                                inputId="max-time" 
                                name="Max Time (minutes)" 
                                v-model="editState.maxMinuites" />
                            </div>
                          </div>
                        </div>

                        <div class="standard-form-group-large form-group-checkbox">
                          <label>Preferred Transportation Methods</label>
                          <div class="row">
                            <div class="col-12 col-sm-6 mb-2">
                              <checkbox-input 
                                name="method-car" 
                                hideLabel="true" 
                                labelName="Car" 
                                inputId="method-car"
                                v-model="editState.methodCar" 
                                label='Car' />
                            </div>
                            <div class="col-12 col-sm-6 align-vertical">
                              <text-input 
                                inputId="car-details" 
                                name="car-details" 
                                hideLabel="true" 
                                v-model="editState.carDetails" />
                            </div>
                            <div class="col-12 col-sm-6 mb-2">
                              <checkbox-input 
                                name="method-public" 
                                hideLabel="true" 
                                labelName="Public Transportation"
                                inputId="method-public" 
                                v-model="editState.methodPublic" 
                                label='Public Transportation' />
                            </div>
                            <div class="col-12 col-sm-6 align-vertical">
                              <text-input 
                                inputId="public-details" 
                                name="public-details" 
                                hideLabel="true" 
                                v-model="editState.publicDetails" />
                            </div>
                            <div class="col-12 col-sm-6 mb-2">
                              <checkbox-input 
                                name="method-ride" 
                                hideLabel="true" 
                                labelName="Ride Share" 
                                inputId="method-ride"
                                v-model="editState.methodRide" 
                                label='Ride Share' />
                            </div>
                            <div class="col-12 col-sm-6 align-vertical">
                              <text-input 
                                inputId="ride-details" 
                                name="ride-details" 
                                hideLabel="true" 
                                v-model="editState.rideDetails" />
                            </div>
                            <div class="col-12 col-sm-6 mb-2">
                              <checkbox-input 
                                name="method-medicaid" 
                                hideLabel="true" 
                                labelName="Medicaid Transportation"
                                inputId="method-medicaid" 
                                v-model="editState.methodMedicaid" 
                                label='Medicaid Transportation' />
                            </div>
                            <div class="col-12 col-sm-6 align-vertical">
                              <text-input 
                                inputId="medicaid-details" 
                                name="medicaid-details" 
                                hideLabel="true"
                                v-model="editState.medicaidDetails" />
                            </div>
                            <div class="col-12 col-sm-6 mb-2">
                              <checkbox-input 
                                name="method-other" 
                                hideLabel="true" 
                                labelName="Other" 
                                inputId="method-other"
                                v-model="editState.methodOther" 
                                label='Other' />
                            </div>
                            <div class="col-12 col-sm-6 align-vertical">
                              <text-input 
                                inputId="other-details" 
                                name="other-details" 
                                hideLabel="true" 
                                v-model="editState.otherDetails" />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="standard-form-group-3-quarters">
                          <text-area-input 
                            input-id="additional-information" 
                            name="Mobility Impairments and Devices"
                            rows="4"
                            :showToolTip="true"
                            toolTipText="Describe the patient's mobility impairments and devices and how they impact transportation and scheduling."
                            v-model="editState.mobilityInpairmentsDevices" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="standard-form-group-3-quarters">
                          <text-area-input 
                            input-id="inpairments-devices" 
                            name="Comments"
                            rows="4"
                            v-model="editState.comments" />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="saveTransportation"
              :label="`Save ${title}`"
              :cancelButton="true"   
              @save="performSave"     
            />
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import NumberInput from '@/components/shared/NumberInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
  import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

  const EDITSTATE_KEY = 'editState';

  interface TransportationandMobilityForm {
    hasTransportation: boolean | null,
    avoidCities: string | null,
    avoidHighways: string | null,
    gasMoneySensitivity: string | null,
    mobilityChallenges: string | null,
    maxMiles: string | null,
    maxMinuites: string | null,
    methodCar: string,
    carDetails: string,
    methodPublic: string,
    publicDetails: string,
    methodRide: string,
    rideDetails: string,  
    methodMedicaid: string,
    medicaidDetails: string,
    methodOther: string,
    otherDetails: string,
    mobilityInpairmentsDevices: string,
    comments: string
  }

  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout,
      NumberInput,
      BooleanRadioInput
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoTransportationAndMobility extends Vue {

    private title: any = "Transportation and Mobility";

    private editState: TransportationandMobilityForm = {
      hasTransportation: null,
      avoidCities: null,
      avoidHighways: null,
      gasMoneySensitivity: null,
      mobilityChallenges: null,
      maxMiles: null,
      maxMinuites: null,
      methodCar: '',
      carDetails: '',
      methodPublic: '',
      publicDetails: '',
      methodRide: '',
      rideDetails: '',  
      methodMedicaid: '',
      medicaidDetails: '',
      methodOther: '',
      otherDetails: '',
      mobilityInpairmentsDevices: '',
      comments: ''
    };

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    get isProtoTransportationAndMobilityEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.TransportationAndMobility);
    }

    // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.saveTransportation as SaveToolbar;
    }

     // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
     async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.TransportationAndMobility, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: TransportationandMobilityForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        hasTransportation: response.hasTransportation,
        avoidCities: response.avoidCities,
        avoidHighways: response.avoidHighways,
        gasMoneySensitivity : response.gasMoneySensitivity,
        mobilityChallenges : response.mobilityChallenges,
        maxMiles : response.maxMiles,
        maxMinuites :  response.maxMinuites,
        methodCar : response.methodCar,
        carDetails : response.carDetails,
        methodPublic : response.methodPublic,
        publicDetails : response.publicDetails,
        methodRide : response.methodRide,
        rideDetails :  response.rideDetails,
        methodMedicaid : response.methodMedicaid,
        medicaidDetails : response.medicaidDetails,
        methodOther : response.methodOther,
        otherDetails : response.otherDetails,
        mobilityInpairmentsDevices : response.mobilityInpairmentsDevices,
        comments : response.comments
      };
    }

    public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: TransportationandMobilityForm = {
        hasTransportation: this.editState.hasTransportation,
        avoidCities: this.editState.avoidCities,
        avoidHighways: this.editState.avoidHighways,
        gasMoneySensitivity : this.editState.gasMoneySensitivity,
        mobilityChallenges : this.editState.mobilityChallenges,
        maxMiles : this.editState.maxMiles,
        maxMinuites :  this.editState.maxMinuites,
        methodCar : this.editState.methodCar,
        carDetails : this.editState.carDetails,
        methodPublic : this.editState.methodPublic,
        publicDetails : this.editState.publicDetails,
        methodRide : this.editState.methodRide,
        rideDetails :  this.editState.rideDetails,
        methodMedicaid : this.editState.methodMedicaid,
        medicaidDetails : this.editState.medicaidDetails,
        methodOther : this.editState.methodOther,
        otherDetails : this.editState.otherDetails,
        mobilityInpairmentsDevices : this.editState.mobilityInpairmentsDevices,
        comments : this.editState.comments
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 
  }

</script>

<style>

.form-group-checkbox .col-12, .form-group-checkbox .col-6{

  padding-top: 0;
  padding-bottom: 0;
}

.form-group-checkbox .form-check-inline {
    width: 100% !important;
}
.form-group-checkbox .form-check-inline label {
    width: 100% !important;
    padding-top: .43rem;
}

.align-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

</style>
