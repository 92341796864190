<template>
  <div v-if="isProtoKidneySpecificDetailsEnabled">
    <card-section
      section-id="proto_kidney_specific_details"
    >
      <template v-slot:header>
        Kidney Specific Details
      </template>
      <template v-slot:body>
        <proto-kidney-information />
      </template>
    </card-section>
</div>
</template>

<script lang="ts">
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ProtoKidneyInformation from '@/prototypes/organs/kidney/ProtoKidneyInformation.vue';

import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';


@Component({
  components: {
    CardSection,
    ProtoKidneyInformation
  }
})
export default class ProtoKidneySpecificDetails extends Vue {
  get isProtoKidneySpecificDetailsEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.KidneySpecificDetails);
  }
}
</script>
