import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.getFormControlStyling)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.getInputStyling)
      }, [
        _createVNode(_component_select_input, _mergeProps({
          modelValue: _ctx.selectLocalValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectLocalValue) = $event)),
          name: _ctx.name,
          "select-id": _ctx.selectId,
          "validation-id": _ctx.validationId,
          options: _ctx.options,
          label: _ctx.label,
          "null-text": _ctx.nullText,
          "text-key": _ctx.textKey,
          "value-key": _ctx.valueKey,
          "undefined-text": _ctx.undefinedText,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          "hide-label": _ctx.hideLabel,
          rules: _ctx.rules,
          ruleKey: _ctx.ruleKey,
          crossValues: _ctx.crossValues,
          numeric: _ctx.numeric
        }, _toHandlers(_ctx.selectEvents())), null, 16, ["modelValue", "name", "select-id", "validation-id", "options", "label", "null-text", "text-key", "value-key", "undefined-text", "disabled", "readonly", "hide-label", "rules", "ruleKey", "crossValues", "numeric"])
      ], 2),
      (_ctx.enableOther)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.showOther && _ctx.otherTitle.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_text_input, {
                    inputId: "id",
                    label: _ctx.otherTitle,
                    name: _ctx.otherTitle,
                    value: "",
                    disabled: "true",
                    required: "true"
                  }, null, 8, ["label", "name"])
                ]))
              : (_ctx.showOther)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.stackInputs ? 'col-12 pt-0' : 'col-6')
                  }, [
                    _renderSlot(_ctx.$slots, "other")
                  ], 2))
                : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 2))
}